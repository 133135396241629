import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { Media } from '../../models/medias/media.model';
import { Facebook } from '../../models/medias/facebook.model';
import { Instagram } from '../../models/medias/instagram.model';
import { Twitter } from '../../models/medias/twitter.model';

import { AppService } from '../../services/app/app.service';
import { MediaService } from '../../services/media/media.service';
import { FacebookService } from '../../services/facebook/facebook.service';
import { InstagramService } from '../../services/instagram/instagram.service';
import { TwitterService } from '../../services/twitter/twitter.service';
import { StorageService } from '../../services/storage/storage.service';

import { environment } from 'src/environments/environment';

declare let $: any;
declare let FB: any;

/** Modal's views list */
enum VIEW {
  MAIN,
  FACEBOOK_SELECTION,
  INSTAGRAM_SELECTION
}

@Component({
  selector: 'app-social-accounts-modal',
  templateUrl: './social-accounts-modal.component.html',
  styleUrls: ['./social-accounts-modal.component.scss']
})
export class SocialAccountsModalComponent implements OnInit {
  @ViewChild('socialAccountsModal') socialAccountsModal: TemplateRef<any>;

  readonly TWITTER_API_BASE_URL: string;
  readonly viewEnum: any;

  view: VIEW;

  facebookPages: Facebook[];
  selectedFacebookPages: string[];
  instagramAccounts: Instagram[];
  selectedInstagramAccounts: string[];

  onDelete: string[];
  onGetFacebookPages: string;
  onSaveFacebookPages: string;
  onGetInstagramAccounts: string;
  onSaveInstagramAccounts: string;
  onTwitterConnect: string;
  onSaveTwitterAccount: string;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private notifierService: NotifierService,
    private modalService: NgbModal,
    private facebookService: FacebookService,
    private instagramService: InstagramService,
    private twitterService: TwitterService,
    private storageService: StorageService,

    public appService: AppService,
    public mediaService: MediaService
  ) {
    this.TWITTER_API_BASE_URL = environment.TWITTER_API_BASE_URL;
    this.viewEnum = VIEW;
  }

  get isFacebookSelectionLimitReached(): boolean {
    return this.selectedFacebookPages.length + this.mediaService.medias.length > this.appService.maxMedias;
  }

  get isInstagramSelectionLimitReached(): boolean {
    return this.selectedInstagramAccounts.length + this.mediaService.medias.length > this.appService.maxMedias;
  }

  ngOnInit() {}

  init() {
    this.view = VIEW.MAIN;
    this.selectedFacebookPages = [];
    this.selectedInstagramAccounts = [];
    this.onDelete = [];
    this.onGetFacebookPages = 'false';
    this.onSaveFacebookPages = 'false';
    this.onGetInstagramAccounts = 'false';
    this.onSaveInstagramAccounts = 'false';
    this.onTwitterConnect = 'false';
    this.onSaveTwitterAccount = 'false';

    setTimeout(() => this.setConnectedAccountsDivHeight());
  }

  getFacebookPages(facebookUserToken: string) {
    this.view = VIEW.FACEBOOK_SELECTION;
    this.onGetFacebookPages = 'true';

    this.facebookService.getPages(facebookUserToken).subscribe(
      (facebookPages: Facebook[]) => {
        if (!facebookPages || facebookPages.length === 0) {
          this.onGetFacebookPages = 'error';
        } else {
          this.facebookPages = facebookPages;
          this.onGetFacebookPages = 'success';

          setTimeout(() => this.setFacebookSelectionPagesDivHeight());
        }
      },
      () => {
        this.onGetFacebookPages = 'error';
      }
    );
  }

  getInstagramAccounts(facebookUserToken: string) {
    this.view = VIEW.INSTAGRAM_SELECTION;
    this.onGetInstagramAccounts = 'true';

    this.instagramService.getAccounts(facebookUserToken).subscribe(
      (instagramAccounts: Instagram[]) => {
        if (!instagramAccounts || instagramAccounts.length === 0) {
          this.onGetInstagramAccounts = 'error';
        } else {
          this.instagramAccounts = instagramAccounts;
          this.onGetInstagramAccounts = 'success';

          setTimeout(() => this.setInstagramSelectionAccountsDivHeight());
        }
      },
      () => {
        this.onGetInstagramAccounts = 'error';
      }
    );
  }

  setConnectedAccountsDivHeight() {
    const windowHeight = $(window).innerHeight();
    const headerHeight = $('.modal-header').innerHeight();
    const connectAccountHeight = $('#this-connect-accounts').innerHeight();
    const connectedAccountsDivEl = $('#this-connected-accounts > div');
    const connectedAccountsMaxHeight = windowHeight - headerHeight - connectAccountHeight - 200;

    connectedAccountsDivEl.css('max-height', connectedAccountsMaxHeight);
  }

  setFacebookSelectionPagesDivHeight() {
    if (this.view === VIEW.FACEBOOK_SELECTION && this.onGetFacebookPages === 'success') {
      const windowHeight = $(window).innerHeight();
      const facebookSelectionTableEl = $('#this-facebook-selection-table');
      const facebookSelectionTableMaxHeight = windowHeight - 300;

      facebookSelectionTableEl.css('max-height', facebookSelectionTableMaxHeight);
    }
  }

  setInstagramSelectionAccountsDivHeight() {
    if (this.view === VIEW.INSTAGRAM_SELECTION && this.onGetInstagramAccounts === 'success') {
      const windowHeight = $(window).innerHeight();
      const instagramSelectionTableEl = $('#this-instagram-selection-table');
      const instagramSelectionTableMaxHeight = windowHeight - 300;

      instagramSelectionTableEl.css('max-height', instagramSelectionTableMaxHeight);
    }
  }

  open() {
    this.init();

    this.modalService.open(this.socialAccountsModal).result.finally(() => {
      this.router.navigate([], {
        queryParams: {
          socialAccountsModal: null
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  saveTwitterAccount(oauthToken: string, verifier: string) {
    const params = {
      token: oauthToken,
      verifier
    };

    this.onSaveTwitterAccount = 'true';

    this.twitterService.saveAccount(params).subscribe(
      (account: Twitter) => {
        if (Object.keys(account).length) {
          const { _id, id, name, image, token, tokenSecret } = account;

          this.mediaService.medias = this.mediaService.medias.filter(
            (media: Media) => (media.id === id && media.type === 'twitter') === false
          );
          this.mediaService.medias.push(
            new Media({
              _id,
              id,
              name,
              image,
              type: 'twitter',
              token,
              tokenSecret
            })
          );

          this.mediaService.orderMedias();
        }

        this.onSaveTwitterAccount = 'success';

        this.mediaService.dispatchMediasChange();

        setTimeout(() => {
          const twitterConnectedEvent = new CustomEvent('twitterConnected', {
            bubbles: true,
            cancelable: true
          });

          document.dispatchEvent(twitterConnectedEvent);
          this.setConnectedAccountsDivHeight();
        });
      },
      () => (this.onSaveTwitterAccount = 'error')
    );
  }

  onFacebookConnectClick() {
    FB.login(
      (res: any) => {
        if (res.status === 'connected') {
          const event = new CustomEvent('mediaFacebookLogin', {
            bubbles: true,
            cancelable: true,
            detail: {
              token: res.authResponse.accessToken
            }
          });

          document.dispatchEvent(event);
        }
      },
      { scope: 'pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_posts' }
    );
  }

  onFacebookSelectionTryAgainClick() {
    this.onFacebookConnectClick();
  }

  onFacebookSelectionCancelClick() {
    this.init();
  }

  onFacebookSelectionPageClick(id: string) {
    const index = this.selectedFacebookPages.indexOf(id);

    if (index > -1) {
      this.selectedFacebookPages.splice(index, 1);
    } else {
      this.selectedFacebookPages.push(id);
    }
  }

  onFacebookSelectionSaveClick() {
    const pages = this.facebookPages.filter((page: Facebook) => this.selectedFacebookPages.indexOf(page.id) > -1);
    const pagesIds = pages.map((page: Facebook) => page.id);

    this.onSaveFacebookPages = 'true';

    this.facebookService.savePages(pages).subscribe(
      (_ids: any[]) => {
        const duplicateMedias = this.mediaService.medias.filter(
          (media: Media) => pagesIds.includes(media.id) === true && media.type === 'facebook'
        );
        this.mediaService.medias = this.mediaService.medias.filter(
          (media: Media) => (pagesIds.includes(media.id) === true && media.type === 'facebook') === false
        );

        for (let i = 0; i < pages.length; i += 1) {
          if (_ids[i]) {
            this.mediaService.medias.push(
              new Media({
                _id: _ids[i]._id,
                type: 'facebook',
                ...pages[i]
              })
            );
          }
        }

        this.mediaService.medias.push(...duplicateMedias);

        this.init();
        this.mediaService.orderMedias();
        this.mediaService.dispatchMediasChange();
      },
      () => (this.onSaveFacebookPages = 'error')
    );
  }

  onInstagramConnectClick() {
    FB.login(
      (res: any) => {
        if (res.status === 'connected') {
          const event = new CustomEvent('mediaInstagramLogin', {
            bubbles: true,
            cancelable: true,
            detail: {
              token: res.authResponse.accessToken
            }
          });

          document.dispatchEvent(event);
        }
      },
      { scope: 'pages_show_list,pages_read_engagement,pages_read_user_content,instagram_content_publish' }
    );
  }

  onInstagramSelectionTryAgainClick() {
    this.onInstagramConnectClick();
  }

  onInstagramSelectionCancelClick() {
    this.init();
  }

  onInstagramSelectionAccountClick(id: string) {
    const index = this.selectedInstagramAccounts.indexOf(id);

    if (index > -1) {
      this.selectedInstagramAccounts.splice(index, 1);
    } else {
      this.selectedInstagramAccounts.push(id);
    }
  }

  onInstagramSelectionSaveClick() {
    const accounts = this.instagramAccounts.filter(
      (account: Instagram) => this.selectedInstagramAccounts.indexOf(account.id) > -1
    );
    const accountsIds = accounts.map((account: Instagram) => account.id);

    this.onSaveInstagramAccounts = 'true';

    this.instagramService.saveAccounts(accounts).subscribe(
      (_ids: any[]) => {
        const duplicateMedias = this.mediaService.medias.filter(
          (media: Media) => accountsIds.includes(media.id) === true && media.type === 'instagram'
        );
        this.mediaService.medias = this.mediaService.medias.filter(
          (media: Media) => (accountsIds.includes(media.id) === true && media.type === 'instagram') === false
        );

        for (let i = 0; i < accounts.length; i += 1) {
          if (_ids[i]) {
            this.mediaService.medias.push(
              new Media({
                _id: _ids[i]._id,
                type: 'instagram',
                ...accounts[i]
              })
            );
          }
        }

        this.mediaService.medias.push(...duplicateMedias);

        this.init();
        this.mediaService.orderMedias();
        this.mediaService.dispatchMediasChange();
      },
      () => (this.onSaveInstagramAccounts = 'error')
    );
  }

  onDeleteMediaClick(media: Media) {
    const { _id } = media;

    this.onDelete[media._id] = 'true';

    this.mediaService
      .deleteMedia(_id)
      .toPromise()
      .then(() => {
        this.mediaService.medias = this.mediaService.medias.filter((m: Media) => m._id !== _id);

        /**
         * Dispatch a custom delete event to indicate that a
         * social media account has been disconnected.
         */
        const event = new CustomEvent('mediaDelete', {
          bubbles: true,
          cancelable: true,
          detail: {
            ...media
          }
        });

        document.dispatchEvent(event);
        this.mediaService.dispatchMediasChange();
      })
      .catch(() => {
        this.notifierService.notify('error', this.translateService.instant('MODAL_SOCIAL_ACCOUNTS.ERRORS.DISCONNECT'));
      })
      .finally(() => {
        delete this.onDelete[media._id];
      });
  }

  onTwitterConnectClick() {
    this.onTwitterConnect = 'false';

    this.twitterService.getRequestToken().subscribe(
      (data: any) => {
        const url = `${this.TWITTER_API_BASE_URL}oauth/authenticate?oauth_token=${data.token}&force_login=1`;
        const currentRoute = this.router.url;

        this.storageService.set('previousRoute', currentRoute);
        window.open(url, '_self');
      },
      () => {
        this.onTwitterConnect = 'error';
      }
    );
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setConnectedAccountsDivHeight();
    this.setFacebookSelectionPagesDivHeight();
    this.setInstagramSelectionAccountsDivHeight();
  }

  @HostListener('document:mediaFacebookLogin', ['$event.detail.token'])
  onMediaFacebookLogin(token: string) {
    this.getFacebookPages(token);
  }

  @HostListener('document:mediaInstagramLogin', ['$event.detail.token'])
  onMediaInstagramLogin(token: string) {
    this.getInstagramAccounts(token);
  }

  @HostListener('document:openSocialAccountsModal', ['$event.detail'])
  onOpenSocialAccountsModal(detail: any) {
    const { action } = detail;

    this.open();

    if (action === 'connect-twitter') {
      this.saveTwitterAccount(detail.token, detail.verifier);
    }
  }
}
