import { Injectable, Component, HostListener, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  NgbDatepicker,
  NgbTimepicker,
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDate
} from '@ng-bootstrap/ng-bootstrap';

import { Moment } from 'moment';

import { DateTime } from '../../models/misc/date.model';
import { Time } from '../../models/misc/time.model';

import { AppService } from '../../services/app/app.service';
import { ToolsService } from '../../services/tools/tools.service';
import { DateService } from '../../services/date/date.service';

import en from '../../../../assets/i18n/en.json';
import fr from '../../../../assets/i18n/fr.json';

declare let $: any;

@Injectable()
export class DateAdapter extends NgbDateAdapter<string> {
  constructor(private dateService: DateService) {
    super();
  }

  fromModel(value: string | null): NgbDateStruct | null {
    return this.dateService.getDateStruct(value);
  }

  toModel(date: NgbDateStruct | null): string | null {
    return this.dateService.getDateString(date);
  }
}

@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
  constructor(private dateService: DateService) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    return this.dateService.getDateStruct(value);
  }

  format(date: NgbDateStruct | null): string | null {
    return this.dateService.getDateString(date);
  }
}

@Injectable()
export class DatePickerTranslations extends NgbDatepickerI18n {
  constructor(private dateService: DateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.dateService.getDayShortName(weekday);
  }

  getMonthShortName(month: number): string {
    return this.dateService.getMonthName(month);
  }

  getMonthFullName(month: number): string {
    return this.dateService.getMonthName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.dateService.getDateString(date);
  }
}

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: DatePickerTranslations },
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: NgbDateParserFormatter, useClass: DateFormatter }
  ]
})
export class DateTimePickerComponent implements OnInit {
  readonly DEFAULT_LEFT_POSITION: string;
  readonly MONTHS: { en: string[]; fr: string[] };

  @ViewChild(NgbDatepicker) datePickerComponent: NgbDatepicker;
  @ViewChild(NgbTimepicker) timePickerComponent: NgbTimepicker;
  @Input() anchorEl: HTMLElement;
  @Input() setTop: boolean;
  @Input() setLeft: boolean;
  @Input() minDate: DateTime;
  @Input() maxDate: DateTime;
  @Output() dateSelected: EventEmitter<DateTime>;

  dateTimePickerEl: any;
  date: NgbDateStruct | string;
  savedDate: NgbDateStruct | string;
  savedTime: FormControl;
  hour: FormControl;
  minute: FormControl;
  minDatePicker: NgbDateStruct;
  minTimePicker: Time;
  maxDatePicker: NgbDateStruct;
  maxTimePicker: Time;
  meridianSelected: string;
  show: boolean;

  constructor(public appService: AppService, private toolsService: ToolsService) {
    this.DEFAULT_LEFT_POSITION = '-1000px';
    this.MONTHS = {
      en: Object.values(en.GENERAL.MONTHS),
      fr: Object.values(fr.GENERAL.MONTHS)
    };

    this.show = false;
    this.dateSelected = new EventEmitter();

    this.hour = new FormControl('');
    this.minute = new FormControl('');
    this.savedTime = new FormControl('');
  }

  get meridian(): string {
    if (this.appService.lang === 'en') {
      if (this.hour.value < 12) {
        return 'AM';
      }

      return 'PM';
    }

    return null;
  }

  get todayMoment(): Moment {
    return this.toolsService.moment() as Moment;
  }

  get currentDate(): NgbDateStruct {
    return {
      year: this.todayMoment.year(),
      month: this.todayMoment.month() + 1,
      day: this.todayMoment.date()
    };
  }

  get currentTime(): { hour: number; minute: number } {
    return {
      hour: this.todayMoment.hour(),
      minute: this.todayMoment.minute()
    };
  }

  get dateTimestamp(): number {
    let { day }: any = <NgbDateStruct>this.savedDate;
    let { month }: any = <NgbDateStruct>this.savedDate;
    let { year }: any = <NgbDateStruct>this.savedDate;
    let hour: any = this.hour.value;
    let minute: any = this.minute.value;

    day = Number(day);
    month = Number(month) - 1;
    year = Number(year);
    hour = Number(hour);
    minute = Number(minute);

    return new Date(year, month, day, hour, minute).getTime();
  }

  get dateTimeDDMMYYYYHHSS(): string {
    let { day }: any = <NgbDateStruct>this.savedDate;
    let { month }: any = <NgbDateStruct>this.savedDate;
    let { year }: any = <NgbDateStruct>this.savedDate;
    let hour: any = this.hour.value;
    let minute: any = this.minute.value;

    day = Number(day);
    month = Number(month);
    year = Number(year);
    hour = Number(hour);
    minute = Number(minute);

    if (day < 10) {
      day = `0${day}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    if (hour < 10) {
      hour = `0${hour}`;
    }

    if (minute < 10) {
      minute = `0${minute}`;
    }

    return `${day}-${month}-${year} ${hour}:${minute}`;
  }

  get isSelectedDateEqCurrentDate(): boolean {
    return (
      this.datePickerComponent &&
      JSON.stringify(this.datePickerComponent.model.selectedDate) === JSON.stringify(this.currentDate)
    );
  }

  get selectedTimeStr(): string {
    let hour = this.hour.value;
    let minute = this.minute.value;
    let time;

    if (this.meridian) {
      if (hour === 0) {
        hour = 12;
      } else if (hour > 12) {
        hour -= 12;
      }
    }

    hour = `${hour}`;
    minute = `${minute}`;

    if (hour.length === 1) {
      hour = `0${hour}`;
    }

    if (minute.length === 1) {
      minute = `0${minute}`;
    }

    time = `${hour}:${minute}`;

    if (this.meridian) {
      time += ` ${this.meridian}`;
    }

    return time;
  }

  get savedTimeStr(): string {
    let hour: any = Number(this.savedTime.value.hour);
    let minute: any = Number(this.savedTime.value.minute);
    let time;
    let meridian;

    if (this.meridian) {
      if (hour < 12) {
        meridian = 'AM';
      } else {
        meridian = 'PM';
      }

      if (hour === 0) {
        hour = 12;
      } else if (hour > 12) {
        hour -= 12;
      }
    }

    hour = `${hour}`;
    minute = `${minute}`;

    if (hour.length === 1) {
      hour = `0${hour}`;
    }

    if (minute.length === 1) {
      minute = `0${minute}`;
    }

    time = `${hour}:${minute}`;

    if (meridian) {
      time += ` ${meridian}`;
    }

    return time;
  }

  get dateString(): string {
    if (this.savedDate) {
      const { month, year } = <NgbDateStruct>this.savedDate;
      const monthName = this.MONTHS[this.appService.lang][month - 1];
      let { day } = <any>(<NgbDateStruct>this.savedDate);

      if (day < 10) {
        day = `0${day}`;
      }

      return `${day} ${monthName} ${year}, ${this.savedTimeStr}`;
    }

    return null;
  }

  get timeValues(): { hour: number; hourStr: number; minute: number[]; minuteStr: string[] }[] {
    let values = [];
    let startHour = 0;
    let startMinute = 0;

    if (this.datePickerComponent && this.isSelectedDateEqCurrentDate) {
      const currentHour = this.currentTime.hour;
      const currentMinute = this.currentTime.minute;

      if (currentMinute > 55) {
        startMinute = 0;
        startHour = currentHour + 1;
      } else {
        const rest = currentMinute % 10;
        const valueStr = currentMinute.toString();

        startHour = currentHour;

        if (rest > 0 && rest < 5) {
          if (currentMinute > 10) {
            startMinute = Number(valueStr[0] + 5);
          } else {
            startMinute = 5;
          }
        } else if (rest > 5) {
          if (currentMinute > 10) {
            startMinute = Number(valueStr[0]) * 10 + 10;
          } else {
            startMinute = 10;
          }
        } else {
          startMinute = currentMinute + 5;
        }
      }
    }

    for (let hour = startHour; hour < 24; hour += 1) {
      const value: any = {};
      let hourStr = `${hour}`;

      if (hourStr.length === 1) {
        hourStr = `0${hourStr}`;
      }

      value.hour = hour;
      value.hourStr = hourStr;
      value.minute = [];
      value.minuteStr = [];

      for (let minute = startMinute; minute < 60; minute += 5) {
        let minuteStr = `${minute}`;

        if (minuteStr.length === 1) {
          minuteStr = `0${minuteStr}`;
        }

        value.minute.push(minute);
        value.minuteStr.push(minuteStr);
      }

      values.push(value);

      if (hour === startHour) {
        startMinute = 0;
      }
    }

    if (this.meridian) {
      if (this.meridianSelected === 'AM') {
        values = values.filter((value: any) => value.hour < 12);

        for (const value of values) {
          if (value.hour === 0) {
            value.hourStr = `12`;
          } else if (value.hour < 10) {
            value.hourStr = `0${value.hour}`;
          } else {
            value.hourStr = `${value.hour}`;
          }
        }
      } else {
        values = values.filter((value: any) => value.hour >= 12);

        for (const value of values) {
          if (value.hour === 12) {
            value.hourStr = `${value.hour}`;
          } else {
            const newHour = value.hour - 12;

            if (newHour < 10) {
              value.hourStr = `0${newHour}`;
            } else {
              value.hourStr = `${newHour}`;
            }
          }
        }
      }
    }

    return values;
  }

  get timeHours(): number[] {
    return this.timeValues.map((time: any) => time.hour);
  }

  get timeHoursStr(): string[] {
    return this.timeValues.map((time: any) => time.hourStr);
  }

  get timeMinutes(): number[] {
    const hour = Number(this.hour.value);
    const timeValue = this.timeValues.find((time: any) => time.hour === hour);

    if (timeValue) {
      return timeValue.minute;
    }

    return [];
  }

  get timeMinutesStr(): string[] {
    const hour = Number(this.hour.value);
    const timeValue = this.timeValues.find((time: any) => time.hour === hour);

    if (timeValue) {
      return timeValue.minuteStr;
    }

    return [];
  }

  get minError(): boolean {
    if (
      this.datePickerComponent &&
      JSON.stringify(this.currentDate) === JSON.stringify(this.datePickerComponent.model.selectedDate)
    ) {
      const { year, month, day } = this.currentDate;
      const selectedDateMoment = this.toolsService.moment({ dateArray: [year, month, day], format: false }) as Moment;

      selectedDateMoment.hour(this.hour.value).minute(this.minute.value);

      return selectedDateMoment.isBefore(this.todayMoment);
    }

    return false;
  }

  ngOnInit() {
    this.dateTimePickerEl = $('app-date-time-picker #this');
    this.dateTimePickerEl.css('left', this.DEFAULT_LEFT_POSITION);

    if (this.setTop === undefined) {
      this.setTop = true;
    }

    if (this.setLeft === undefined) {
      this.setLeft = true;
    }

    setTimeout(() => {
      if (this.meridian) {
        if (this.currentTime.hour < 12) {
          this.meridianSelected = 'AM';
        } else {
          this.meridianSelected = 'PM';
        }
      }
    });
  }

  reset() {
    this.show = false;

    this.hour.setValue('');
    this.minute.setValue('');
    this.savedTime.setValue('');

    this.date = undefined;
    this.savedDate = undefined;
    this.minDatePicker = undefined;
    this.minTimePicker = undefined;
    this.maxDatePicker = undefined;
    this.maxTimePicker = undefined;
    this.meridianSelected = undefined;
  }

  setSelectedDate() {
    if (this.date === undefined || this.savedDate === undefined) {
      if (this.minDatePicker) {
        const { day, month, year } = this.minDate;

        this.date = `${day} ${this.MONTHS[this.appService.lang][month - 1]} ${year}`;
        this.datePickerComponent.model.selectedDate = new NgbDate(year, month, day);
      } else {
        const moment = this.toolsService.moment() as Moment;
        const day = moment.date();
        const month = moment.month();
        const year = moment.year();

        this.date = `${day} ${this.MONTHS[this.appService.lang][month]} ${year}`;
        this.datePickerComponent.model.selectedDate = new NgbDate(year, month, day);
      }
    }
  }

  open() {
    const { left, top } = this.anchorEl.getBoundingClientRect();
    let newTop = top - this.dateTimePickerEl.height();

    this.show = true;

    if (this.minDate) {
      const { day, month, year, hour, minute } = this.minDate;

      this.minDatePicker = { day, month, year };
      this.minTimePicker = { hour, minute };
    }

    if (this.maxDate) {
      const { day, month, year, hour, minute } = this.maxDate;

      this.maxDatePicker = { day, month, year };
      this.maxTimePicker = { hour, minute };
    }

    this.setSelectedDate();

    if (newTop < 0) {
      newTop = 0;
    }

    if (this.setTop) {
      this.dateTimePickerEl.css('top', `${newTop}px`);
    }

    if (this.setLeft) {
      this.dateTimePickerEl.css('left', `${left}px`);
    }

    this.dateTimePickerEl.css('max-width', `${this.dateTimePickerEl.width()}px`);

    if (this.timeValues.length === 0) {
      const minDatePicker = this.toolsService.moment() as Moment;

      minDatePicker
        .date(this.minDatePicker.day)
        .month(this.minDatePicker.month - 1)
        .year(this.minDatePicker.year)
        .hour(0)
        .minute(0)
        .second(0);

      minDatePicker.add(1, 'day');

      this.minDate = {
        day: minDatePicker.date(),
        month: minDatePicker.month() + 1,
        year: minDatePicker.year(),
        hour: 0,
        minute: 0
      };
      this.minDatePicker = {
        day: this.minDate.day,
        month: this.minDate.month,
        year: this.minDate.year
      };
      this.minTimePicker = {
        hour: 0,
        minute: 0
      };

      this.setSelectedDate();
    }

    if (!this.hour.value) {
      this.hour.setValue(this.timeHours[0]);
      this.minute.setValue(this.timeMinutes[0]);
    }

    this.datePickerComponent.navigateTo(this.datePickerComponent.model.selectedDate);
    this.datePickerComponent.focus();
  }

  close(resetToSavedDate: boolean = true) {
    if (resetToSavedDate) {
      if (this.savedDate && this.savedTime.value) {
        const { year, month, day } = <NgbDateStruct>this.savedDate;
        const { hour, minute } = this.savedTime.value;

        this.date = `${day} ${this.MONTHS[this.appService.lang][month - 1]} ${year}`;
        this.datePickerComponent.model.selectedDate = new NgbDate(year, month, day);
        this.datePickerComponent.model.focusDate = this.datePickerComponent.model.selectedDate;

        this.hour.setValue(hour);
        this.minute.setValue(minute);
      } else {
        this.date = undefined;
        this.hour.setValue('');
        this.minute.setValue('');
      }
    }

    this.dateTimePickerEl.css('top', 'unset');
    this.dateTimePickerEl.css('right', 'unset');
    this.dateTimePickerEl.css('left', this.DEFAULT_LEFT_POSITION);

    this.show = false;
  }

  onMeridianClick() {
    if (this.isSelectedDateEqCurrentDate) {
      const currentHour = this.currentTime.hour;

      if (currentHour < 12) {
        const hour = Number(this.hour.value);
        let minute = Number(this.minute.value);

        if (this.meridianSelected === 'AM') {
          this.meridianSelected = 'PM';
          this.hour.setValue(hour + 12);
        } else {
          this.meridianSelected = 'AM';

          if (hour - 12 < currentHour) {
            this.hour.setValue(currentHour);
          } else {
            this.hour.setValue(hour - 12);
          }
        }

        const firstMinute = Number(this.timeMinutes[0]);

        if (minute < firstMinute) {
          minute = firstMinute;
        }

        this.minute.setValue(minute);
      }
    } else {
      const hour = Number(this.hour.value);
      const minute = Number(this.minute.value);

      if (this.meridianSelected === 'AM') {
        this.meridianSelected = 'PM';
        this.hour.setValue(hour + 12);
      } else {
        this.meridianSelected = 'AM';
        this.hour.setValue(hour - 12);
      }

      this.minute.setValue(minute);
    }
  }

  onDatePickerChange() {
    if (this.isSelectedDateEqCurrentDate) {
      const hour = Number(this.hour.value);
      const currentHour = Number(this.currentTime.hour);

      if (hour < currentHour) {
        if (currentHour < 12) {
          this.meridianSelected = 'AM';
        } else {
          this.meridianSelected = 'PM';
        }

        this.hour.setValue(this.currentTime.hour);
      }
    }

    const hour = Number(this.timeValues[0].hour);
    const minute = Number(this.timeValues[0].minute[0]);
    const selectedHour = Number(this.hour.value);
    const selectedMinute = Number(this.minute.value);

    if (selectedHour < hour) {
      this.hour.setValue(hour);
      this.minute.setValue(minute);
    } else if (selectedHour === hour && selectedMinute < minute) {
      this.minute.setValue(minute);
    }
  }

  onHourChange() {
    this.minute.setValue(this.timeMinutes[0]);
  }

  onOKClick() {
    const { day, month, year } = <NgbDateStruct>this.date;
    const hour = this.hour.value;
    const minute = this.minute.value;

    this.savedDate = this.datePickerComponent.model.selectedDate;
    this.savedTime.setValue({ hour, minute });

    this.dateSelected.emit({
      day,
      month,
      year,
      hour,
      minute
    });
    this.close(false);
  }

  @HostListener('window:click', ['$event.target'])
  onWindowClick(target: HTMLElement) {
    if (
      this.show === true &&
      this.dateTimePickerEl[0].contains(target) === false &&
      this.anchorEl.contains(target) === false
    ) {
      this.close();
    }
  }
}
