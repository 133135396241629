import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage/storage.service';

@Component({
  selector: 'app-twitter-connect',
  templateUrl: './twitter-connect.component.html',
  styleUrls: ['./twitter-connect.component.scss']
})
export class TwitterConnectComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private storageService: StorageService) {}

  ngOnInit() {
    const previousRouteFull = this.storageService.get('previousRoute');
    const previousRouteSplitted = previousRouteFull.split('?');
    const [previousRoute] = previousRouteSplitted;
    const queryParams = {};

    if (previousRouteFull.includes('?')) {
      const params = previousRouteSplitted[1].split('&');

      for (const param of params) {
        const paramParts = param.split('=');
        queryParams[paramParts[0]] = paramParts[1];
      }
    }

    this.storageService.delete('previousRoute');
    this.router.navigate([previousRoute], { queryParams });

    const token = this.activatedRoute.snapshot.queryParams.oauth_token;
    const verifier = this.activatedRoute.snapshot.queryParams.oauth_verifier;
    const openSocialAccountsEvent = new CustomEvent('openSocialAccountsModal', {
      bubbles: true,
      cancelable: true,
      detail: {
        action: 'connect-twitter',
        token,
        verifier
      }
    });

    document.dispatchEvent(openSocialAccountsEvent);
  }
}
