import { Injectable } from '@angular/core';

import { Media } from '../../models/medias/media.model';
import { Dashboard } from '../../models/dashboard/dashboard.model';

import { StorageService } from '../storage/storage.service';
import { MediaService } from '../media/media.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  publisher: {
    selectedMedias: Media[];
    separateMediaTypes: boolean;
    text: string;
    textFacebook: string;
    textInstagram: string;
    textTwitter: string;
    scheduledDate: string;
    publishMode: string;
    files: {
      all: any[];
      facebook: any[];
      instagram: any[];
      twitter: any[];
    };
    onFacebookPublish: boolean;
    onTwitterPublish: boolean;
    onInstagramPublish: boolean;
  };
  calendar: {
    selectedMedias: Media[];
    scheduledPosts: any;
    selectedMonth: string;
  };
  analytics: {
    boards: Dashboard[];
  };

  constructor(private storageService: StorageService, private mediaService: MediaService) {
    const publisherData = this.storageService.get('publisherData');

    this.initPublisher();

    if (publisherData) {
      const { selectedMedias } = JSON.parse(publisherData);
      this.publisher.selectedMedias = selectedMedias;
    }

    const calendarData = this.storageService.get('calendarData');

    if (calendarData) {
      this.calendar = JSON.parse(calendarData);
    } else {
      this.calendar = {
        selectedMedias: [],
        scheduledPosts: {},
        selectedMonth: null
      };
    }

    const analyticsData = this.storageService.get('analyticsData');

    if (analyticsData) {
      this.analytics = JSON.parse(analyticsData);
    } else {
      this.analytics = { boards: [] };
    }
  }

  initPublisher() {
    this.publisher = {
      selectedMedias: [],
      separateMediaTypes: false,
      text: '',
      textFacebook: '',
      textInstagram: '',
      textTwitter: '',
      scheduledDate: '',
      publishMode: 'now',
      files: {
        all: [],
        facebook: [],
        instagram: [],
        twitter: []
      },
      onFacebookPublish: false,
      onTwitterPublish: false,
      onInstagramPublish: false
    };
  }

  savePublisher() {
    const { selectedMedias } = this.publisher;
    const data = { selectedMedias };

    this.storageService.set('publisherData', JSON.stringify(data));
  }

  saveCalendar() {
    this.storageService.set('calendarData', JSON.stringify(this.calendar));
  }

  saveAnalytics() {
    this.storageService.set('analyticsData', JSON.stringify(this.analytics));
  }

  cleanSelectedMedias(data: any) {
    data.selectedMedias = data.selectedMedias.filter((media: Media) => this.mediaService.mediasIds.includes(media._id));
    data.selectedMedias = Array.from(new Set(data.selectedMedias));
  }
}
