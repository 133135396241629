import { Component, OnInit } from '@angular/core';

import { BoardBaseComponent } from '../board-base/board-base.component';

import { FacebookStats } from 'src/app/shared/models/stats/facebookStats.model';

import { FacebookReactions } from 'src/app/shared/enums/medias/facebook-reactions.enum';
import { Sort } from 'src/app/shared/enums/misc/sort.enum';

import { FacebookService } from 'src/app/shared/services/facebook/facebook.service';

@Component({
  selector: 'app-board-facebook',
  templateUrl: './board-facebook.component.html',
  styleUrls: ['./board-facebook.component.scss']
})
export class BoardFacebookComponent extends BoardBaseComponent implements OnInit {
  stats: FacebookStats[];
  order: { type: FacebookReactions; sort: Sort };

  constructor(private facebookService: FacebookService) {
    super();
    this.REACTIONS = FacebookReactions;
  }

  ngOnInit() {
    this.order = {
      type: FacebookReactions.LIKE,
      sort: Sort.DESC
    };

    this.getStats();
  }

  getStats() {
    this.onGetStats = 'true';

    this.facebookService.getPageStats(this.media.id, this.dateStart, this.dateEnd).subscribe(
      (stats: FacebookStats[]) => {
        if (stats.length) {
          this.stats = stats;
          this.onGetStats = 'success';

          this.sort();
        } else {
          this.onGetStats = 'error';
        }
      },
      () => (this.onGetStats = 'error')
    );
  }

  sort() {
    const sortFn = (stats1: FacebookStats, stats2: FacebookStats) => {
      switch (this.order.type) {
        case FacebookReactions.LIKE:
          return stats1.nbLikes.total - stats2.nbLikes.total;
        case FacebookReactions.LOVE:
          return stats1.nbLoves.total - stats2.nbLoves.total;
        case FacebookReactions.HAHA:
          return stats1.nbHahas.total - stats2.nbHahas.total;
        case FacebookReactions.WOW:
          return stats1.nbWows.total - stats2.nbWows.total;
        case FacebookReactions.ANGRY:
          return stats1.nbAngries.total - stats2.nbAngries.total;
        case FacebookReactions.COMMENT:
          return stats1.nbComments - stats2.nbComments;
        case FacebookReactions.REPLY:
          return stats1.nbReplies - stats2.nbReplies;
        default:
          return stats1.nbLikes.total - stats2.nbLikes.total;
      }
    };

    if (this.order.sort === Sort.ASC) {
      this.stats.sort((stats1: FacebookStats, stats2: FacebookStats) => sortFn(stats1, stats2));
    } else {
      this.stats.sort((stats1: FacebookStats, stats2: FacebookStats) => sortFn(stats2, stats1));
    }
  }
}
