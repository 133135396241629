import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

import { Form } from '../shared/models/forms/form.model';
import { FormSelect } from '../shared/models/forms/form-select.model';

import { AppService } from '../shared/services/app/app.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  currentYear: number;
  languageForm: Form;

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    const langValues = [];

    for (const lang of this.appService.config.LANGUAGES) {
      langValues.push({
        value: lang.code,
        text: `${lang.code.toUpperCase()} - ${lang.name}`
      });
    }

    this.languageForm = new Form([
      new FormSelect({
        name: 'lang',
        control: new FormControl(this.appService.lang),
        values: langValues,
        prependIcon: 'fas fa-globe'
      })
    ]);
  }

  onLangChange() {
    this.appService.setLang(this.languageForm.f.lang.value);
  }
}
