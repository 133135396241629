import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { CalendarComponent } from './calendar.component';

import { CalendarRouting } from './calendar.routing';

@NgModule({
  declarations: [CalendarComponent],
  imports: [CommonModule, SharedModule, CalendarRouting]
})
export class CalendarModule {}
