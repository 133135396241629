import { Component, OnInit, HostListener } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.setContainerHeight();
  }

  @HostListener('window:resize')
  setContainerHeight() {
    const containerEl = $('app-settings > .container');
    const contentEl = $('app-settings > .container > .bg-secondary-2');
    const windowHeight = $(window).innerHeight();
    const headerHeight = $('app-root > header').innerHeight();
    const newHeight = windowHeight - headerHeight;

    containerEl.height(newHeight);
    contentEl.height(newHeight);
  }
}
