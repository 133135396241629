import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';

import { Form } from 'src/app/shared/models/forms/form.model';
import { FormField } from 'src/app/shared/models/forms/form-field.model';

import { AppService } from 'src/app/shared/services/app/app.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
  emailParam: any;

  passwordForgottenForm: Form;
  passwordForgottenError: string;

  onPasswordForgotten: string;

  constructor(private appService: AppService, private authService: AuthService) {}

  ngOnInit(): void {
    this.appService.setTitle('PASSWORD_FORGOTTEN_PAGE.TITLE');

    this.passwordForgottenForm = new Form([
      new FormField({
        name: 'email',
        type: 'email',
        placeholder: 'FORMS.EMAIL',
        control: new FormControl('', [
          Validators.required,
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        ]),
        prependIcon: 'fas fa-user',
        errors: {
          pattern: 'FORMS.ERRORS.EMAIL'
        }
      })
    ]);
  }

  onPasswordForgottenFormSubmit() {
    this.emailParam = {
      email: this.passwordForgottenForm.controls.email.value
    };

    this.onPasswordForgotten = 'true';

    this.authService.passwordForgotten(this.emailParam).subscribe(
      () => {
        this.onPasswordForgotten = 'success';
      },
      (error: HttpErrorResponse) => {
        const { type } = error.error;

        this.onPasswordForgotten = 'error';

        if (type) {
          this.passwordForgottenError = `PASSWORD_FORGOTTEN_PAGE.ERRORS.${error.error.type}`;
        }
      }
    );
  }
}
