import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'files/';
  }

  upload(file: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    return <Observable<any>>this.http.post(`${this.API_PREFIX}upload`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
