import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TwitterConnectComponent } from './twitter-connect/twitter-connect.component';

import { AuthGuard } from '../shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'twitter/connect',
    component: TwitterConnectComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TwitterRouting {}
