import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CookieService } from 'ngx-cookie-service';

import { SharedModule } from './shared/shared.module';
import { TwitterModule } from './twitter/twitter.module';
import { PublisherModule } from './publisher/publisher.module';
import { CalendarModule } from './calendar/calendar.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SettingsModule } from './settings/settings.module';
import { AuthModule } from './auth/auth.module';

import { AppComponent } from './app.component';
import { SocialAccountsModalComponent } from './shared/components/social-accounts-modal/social-accounts-modal.component';

import { AppService } from './shared/services/app/app.service';
import { StorageService } from './shared/services/storage/storage.service';

import { GlobalInterceptor } from './shared/interceptors/global.interceptor';

import { AppRouting } from './app.routing';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(injector: Injector, translateService: TranslateService, appService: AppService) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        translateService.setDefaultLang('en');
        appService.setLang(navigator.language.split('-')[0], true).subscribe(() => resolve(null));
      });
    });
}

@NgModule({
  declarations: [AppComponent, SocialAccountsModalComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    TwitterModule,
    PublisherModule,
    CalendarModule,
    DashboardModule,
    SettingsModule,
    AuthModule,
    AppRouting
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector, TranslateService, AppService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalInterceptor,
      multi: true
    },
    CookieService,
    StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
