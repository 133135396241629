import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import { FormGroup, FormControl } from '@angular/forms';

import { Media } from 'src/app/shared/models/medias/media.model';
import { Dashboard } from '../shared/models/dashboard/dashboard.model';

import { AppService } from '../shared/services/app/app.service';
import { DataService } from '../shared/services/data/data.service';
import { DashboardService } from './dashboard.service';
import { MediaService } from 'src/app/shared/services/media/media.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('newBoardSelect') newBoardSelect: NgSelectComponent;

  boards: Dashboard[];
  medias: Media[];
  newBoardForm: FormGroup;

  onCreateStatsBoard: boolean;

  constructor(
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private appService: AppService,
    private dataService: DataService,
    private dashboardService: DashboardService,

    public mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.appService.setTitle('HEADER.ANALYTICS');

    this.boards = this.dataService.analytics.boards.filter((board: Dashboard) =>
      this.mediaService.mediasIds.includes(board._id)
    );
    this.newBoardForm = new FormGroup({
      media: new FormControl()
    });

    this.setMedias();
    this.dashboardService.getAll().subscribe(
      (boards: Dashboard[]) => {
        if (JSON.stringify(this.boards) !== JSON.stringify(boards)) {
          this.boards = boards;
          this.saveAnalytics();
        }
      },
      () => {
        this.notifierService.notify('error', this.translateService.instant('DASHBOARD_PAGE.ERRORS.LOADING'));
      }
    );
  }

  setMedias() {
    this.medias = this.mediaService.medias.filter((media: Media) => media.type !== 'twitter');
  }

  saveAnalytics() {
    this.dataService.analytics.boards = this.boards;
    this.dataService.saveAnalytics();
  }

  onNewStatisticBoard(media: Media) {
    if (media) {
      this.onCreateStatsBoard = true;

      this.dashboardService
        .createStatsBoard({ _mediaId: media._id })
        .then((dashboard: Dashboard) => {
          this.boards.push(dashboard);
          this.saveAnalytics();
        })
        .catch(() => {
          this.notifierService.notify('error', this.translateService.instant('DASHBOARD_PAGE.ERRORS.NEW_DASHBOARD'));
        })
        .finally(() => {
          this.onCreateStatsBoard = false;
          this.newBoardSelect.handleClearClick();
        });
    }
  }

  onBoardDelete(_id: string) {
    this.boards = this.boards.filter((board: Dashboard) => board._id !== _id);
    this.saveAnalytics();
  }

  @HostListener('document:mediaDelete', ['$event.detail'])
  onSocialAccountDeleted(media: Media) {
    this.boards = this.boards.filter((board: Dashboard) => board._mediaId !== media._id);
    this.saveAnalytics();
  }

  @HostListener('document:mediasChange', ['$event.detail'])
  onMediasChange() {
    this.setMedias();
  }
}
