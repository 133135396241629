import { Component, Input, OnInit } from '@angular/core';

import { BoardBaseComponent } from '../board-base/board-base.component';

import { InstagramStats } from 'src/app/shared/models/stats/instagram-stats.model';

import { InstagramReactions } from 'src/app/shared/enums/medias/instagram-reactions.enum';
import { Sort } from 'src/app/shared/enums/misc/sort.enum';

import { InstagramService } from 'src/app/shared/services/instagram/instagram.service';

@Component({
  selector: 'app-board-instagram',
  templateUrl: './board-instagram.component.html',
  styleUrls: ['./board-instagram.component.scss']
})
export class BoardInstagramComponent extends BoardBaseComponent implements OnInit {
  stats: InstagramStats[];
  order: { type: InstagramReactions; sort: Sort };

  constructor(private instagramService: InstagramService) {
    super();
    this.REACTIONS = InstagramReactions;
  }

  ngOnInit() {
    this.order = {
      type: InstagramReactions.COMMENT,
      sort: Sort.DESC
    };

    this.getStats();
  }

  getStats() {
    this.onGetStats = 'true';

    this.instagramService.getAccountStats(this.media.id, this.dateStart, this.dateEnd).subscribe(
      (stats: InstagramStats[]) => {
        if (stats.length) {
          this.stats = stats;
          this.onGetStats = 'success';

          this.sort();
        } else {
          this.stats = [];
          this.onGetStats = 'error';
        }
      },
      () => (this.onGetStats = 'error')
    );
  }

  sort() {
    const sortFn = (stats1: InstagramStats, stats2: InstagramStats) => {
      switch (this.order.type) {
        case InstagramReactions.COMMENT:
          return stats1.nbComments - stats2.nbComments;
        case InstagramReactions.REPLY:
          return stats1.nbReplies - stats2.nbReplies;
        default:
          return stats1.nbComments - stats2.nbComments;
      }
    };

    if (this.order.sort === Sort.ASC) {
      this.stats.sort((stats1: InstagramStats, stats2: InstagramStats) => sortFn(stats1, stats2));
    } else {
      this.stats.sort((stats1: InstagramStats, stats2: InstagramStats) => sortFn(stats2, stats1));
    }
  }
}
