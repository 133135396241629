import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';

import { SocialAccountsModalComponent } from './shared/components/social-accounts-modal/social-accounts-modal.component';

import { AppService } from './shared/services/app/app.service';
import { UserService } from './shared/services/user/user.service';
import { ConfigService } from './shared/services/config/config.service';
import { MediaService } from './shared/services/media/media.service';
import { AuthService } from './auth/auth.service';
import { StorageService } from './shared/services/storage/storage.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('sessionExpiredModal') sessionExpiredModal: TemplateRef<any>;
  @ViewChild('mediaLoadingErrorModal') mediaLoadingErrorModal: TemplateRef<any>;
  @ViewChild(SocialAccountsModalComponent) socialAccountsModal: SocialAccountsModalComponent;

  haveExcludedRoutes: boolean;

  get showLoggedInHeader(): boolean {
    const disabledRoutes = ['/twitter/connect'];

    return (
      this.appService.onGetConfig === 'success' &&
      this.mediaService.onGetMedias === 'success' &&
      disabledRoutes.includes(this.router.url.split('?')[0]) === false
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private configService: ConfigService,
    private authService: AuthService,
    private storageService: StorageService,

    public appService: AppService,
    public userService: UserService,
    public mediaService: MediaService
  ) {}

  ngOnInit() {
    // Translate the title on language change
    this.translateService.onLangChange.subscribe(() => {
      this.appService.setTitle();
    });

    this.router.events.subscribe((event: any) => {
      const excludedRoutes = ['/register', '/password', '/login'];
      this.haveExcludedRoutes = excludedRoutes.find((route) => this.router.url.startsWith(route)) !== undefined;

      // Check if the token is valid, if not show session expired modal and logout the user
      if (event instanceof NavigationEnd) {
        if (this.haveExcludedRoutes === false) {
          this.authService.authorized().subscribe(
            () => {
              if ([null, undefined].includes(this.userService.email)) {
                this.initData();
              }
            },
            () => {
              this.openSessionExpiredModal();
            }
          );
        } else {
          setTimeout(() => this.setMainHeight());
        }
      }
    });
  }

  async initData() {
    // Get user configuration
    this.appService.onGetConfig = 'true';

    await this.configService
      .getConfig()
      .then((config: any) => {
        const { firstName, lastName, email, lang, maxMedias } = config;

        this.userService.firstName = firstName;
        this.userService.lastName = lastName;
        this.userService.email = email;
        this.appService.maxMedias = maxMedias;

        this.appService.setLang(lang);

        this.appService.onGetConfig = 'success';
      })
      .catch(() => {
        this.appService.onGetConfig = 'error';
      });

    // Get saved social medias
    this.mediaService.onGetMedias = 'true';

    await this.mediaService
      .getMedias()
      .then((data: any) => {
        const { all, medias } = data;

        this.mediaService.setMedias(medias);

        if (all === false) {
          this.modalService.open(this.mediaLoadingErrorModal);
        }

        if (this.activatedRoute.snapshot.queryParams.socialAccountsModal === 'true') {
          this.socialAccountsModal.open();
        }

        this.mediaService.onGetMedias = 'success';
      })
      .catch(() => {
        this.mediaService.onGetMedias = 'error';
      });

    setTimeout(() => this.setMainHeight());
  }

  setMainHeight() {
    const headerHeight = $('app-root header.app-header').innerHeight();
    const windowHeight = $(window).innerHeight();
    const mainHeight = `${windowHeight - headerHeight}px`;

    if (headerHeight === undefined) {
      setTimeout(() => this.setMainHeight(), 1000);
    } else {
      $('main').css('height', mainHeight);
      $('main').css('max-height', mainHeight);
    }
  }

  openSessionExpiredModal() {
    this.modalService.open(this.sessionExpiredModal).result.finally(() => {
      this.logout();
    });
  }

  logout() {
    this.authService
      .logout()
      .toPromise()
      .finally(() => {
        this.appService.onGetConfig = 'false';
        this.mediaService.onGetMedias = 'false';
        this.userService.email = null;

        setTimeout(() => this.storageService.clear());
        this.storageService.deleteCookie('token', '/');
        this.router.navigate(['/login']);
      });
  }

  onLoginAgainClick() {
    this.logout();
  }

  onHeaderSocialAccountsClick() {
    this.router.navigate([], {
      queryParams: { socialAccountsModal: true },
      queryParamsHandling: 'merge'
    });
    this.socialAccountsModal.open();
  }

  onHeaderLogoutClick() {
    this.logout();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setMainHeight();
  }
}
