import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { Form } from 'src/app/shared/models/forms/form.model';
import { FormField } from 'src/app/shared/models/forms/form-field.model';
import { FormSelect } from 'src/app/shared/models/forms/form-select.model';

import { MatchControlValueValidator } from 'src/app/shared/validators/match-control-value.validator';

import { AppService } from 'src/app/shared/services/app/app.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  identityForm: Form;
  passwordForm: Form;
  languageForm: Form;

  onUpdate: string;

  constructor(
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private appService: AppService,
    private userService: UserService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.appService.setTitle('SETTINGS_GENERAL_PAGE.META_TITLE');

    this.onUpdate = 'false';

    this.identityForm = new Form([
      new FormField({
        name: 'firstName',
        type: 'text',
        placeholder: 'FORMS.FIRSTNAME',
        control: new FormControl(this.userService.firstName, [Validators.required]),
        prependIcon: 'fas fa-user',
        errors: {
          pattern: 'FORMS.ERRORS.FIRSTNAME'
        }
      }),
      new FormField({
        name: 'lastName',
        type: 'text',
        placeholder: 'FORMS.LASTNAME',
        control: new FormControl(this.userService.lastName, [Validators.required]),
        prependIcon: 'fas fa-user',
        errors: {
          pattern: 'FORMS.ERRORS.LASTNAME'
        }
      }),
      new FormField({
        name: 'email',
        type: 'email',
        placeholder: 'FORMS.EMAIL',
        control: new FormControl(this.userService.email, [
          Validators.required,
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        ]),
        prependIcon: 'fas fa-user',
        errors: {
          pattern: 'FORMS.ERRORS.EMAIL'
        }
      })
    ]);

    const minlengthError = {
      value: 'FORMS.ERRORS.PASSWORD_MIN_LENGTH',
      translationParams: {
        minlength: this.appService.config.PASSWORD_MIN_LENGTH
      }
    };

    this.passwordForm = new Form([
      new FormField({
        name: 'password',
        type: 'password',
        placeholder: 'FORMS.PASSWORD',
        control: new FormControl(''),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: minlengthError,
          matchcontrol: 'FORMS.ERRORS.PASSWORD_EQUALS_REPEAT_PASSWORD'
        }
      }),
      new FormField({
        name: 'repeatPassword',
        type: 'password',
        placeholder: 'FORMS.REPEAT_PASSWORD',
        control: new FormControl(''),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: minlengthError,
          matchcontrol: 'FORMS.ERRORS.REPEAT_PASSWORD_EQUALS_PASSWORD'
        }
      })
    ]);

    const passwordControl = this.passwordForm.controls.password;
    const repeatPasswordControl = this.passwordForm.controls.repeatPassword;
    const passwordValidators = [Validators.required, Validators.minLength(this.appService.config.PASSWORD_MIN_LENGTH)];

    passwordControl.setValidators([
      ...passwordValidators,
      MatchControlValueValidator(this.passwordForm.controls.repeatPassword)
    ]);

    repeatPasswordControl.setValidators([
      ...passwordValidators,
      MatchControlValueValidator(this.passwordForm.controls.password)
    ]);

    const langValues = [];

    for (const lang of this.appService.config.LANGUAGES) {
      langValues.push({
        value: lang.code,
        text: `${lang.code.toUpperCase()} - ${lang.name}`
      });
    }

    this.languageForm = new Form([
      new FormSelect({
        name: 'lang',
        control: new FormControl(this.appService.lang, [Validators.required]),
        values: langValues,
        prependIcon: 'fas fa-globe'
      })
    ]);
  }

  onUpdateClick() {
    const params: any = {
      firstName: this.identityForm.controls.firstName.value,
      lastName: this.identityForm.controls.lastName.value,
      email: this.identityForm.controls.email.value,
      lang: this.languageForm.controls.lang.value
    };

    if (this.passwordForm.controls.password.value) {
      params.password = this.passwordForm.controls.password.value;
    }

    this.onUpdate = 'true';

    this.settingsService.update(params).subscribe(
      () => {
        this.userService.firstName = params.firstName;
        this.userService.lastName = params.lastName;
        this.userService.email = params.email;

        this.appService.setLang(params.lang);
        setTimeout(() =>
          this.notifierService.notify('success', this.translateService.instant('GENERAL.SUCCESS.UPDATE'))
        );

        this.onUpdate = 'success';
      },
      () => {
        this.onUpdate = 'error';
        this.notifierService.notify('error', this.translateService.instant('GENERAL.ERRORS.UPDATE'));
      }
    );
  }
}
