import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import * as mime from 'mime';

import { Media } from '../shared/models/medias/media.model';
import { Post } from '../shared/models/post/post.model';
import { Form } from '../shared/models/forms/form.model';
import { FormSelect } from '../shared/models/forms/form-select.model';

import { MediaBarComponent } from '../shared/components/media-bar/media-bar.component';

import { AppService } from '../shared/services/app/app.service';
import { DataService } from '../shared/services/data/data.service';
import { FacebookService } from '../shared/services/facebook/facebook.service';
import { InstagramService } from '../shared/services/instagram/instagram.service';
import { TwitterService } from '../shared/services/twitter/twitter.service';
import { ToolsService } from '../shared/services/tools/tools.service';
import { DateService } from '../shared/services/date/date.service';

declare let $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @ViewChild(MediaBarComponent) mediaBar: MediaBarComponent;

  dateForm: Form;
  monthValues: { value: string; text: string }[];
  cases: any[];
  maxDate: number;

  scheduledPosts: any;
  selectedPost: Post;

  getFacebookScheduledPostsSubscription: Subscription;
  getInstagramScheduledPostsSubscription: Subscription;
  getTwitterScheduledPostsSubscription: Subscription;

  onGetFacebookScheduledPosts: boolean;
  onGetInstagramScheduledPosts: boolean;
  onGetTwitterScheduledPosts: boolean;

  constructor(
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private appService: AppService,
    private dataService: DataService,
    private facebookService: FacebookService,
    private instagramService: InstagramService,
    private twitterService: TwitterService,
    private toolsService: ToolsService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.appService.setTitle('HEADER.CALENDAR');

    const data = this.dataService.calendar;
    const maxCases = 7 * 5;

    this.scheduledPosts = data.scheduledPosts;
    this.dataService.cleanSelectedMedias(this.dataService.calendar);

    this.monthValues = [];
    this.cases = [];

    for (let i = 1; i <= maxCases; i += 1) {
      let placement;

      if (i <= 3) {
        placement = 'right-top';
      } else if (i <= 7) {
        placement = 'left-top';
      } else if (i <= 10) {
        placement = 'right';
      } else if (i <= 14) {
        placement = 'left';
      } else if (i <= 17) {
        placement = 'right';
      } else if (i <= 21) {
        placement = 'left';
      } else if (i <= 24) {
        placement = 'right-bottom';
      } else if (i <= 28) {
        placement = 'left-bottom';
      } else {
        placement = 'right-bottom';
      }

      this.cases.push({ index: i, placement });
    }

    for (let i = 0; i < 7; i += 1) {
      const date = (this.toolsService.moment() as Moment).add(i, 'months');
      const year = date.get('year');
      let month = `${date.get('month') + 1}`;

      if (month.length === 1) {
        month = `0${month}`;
      }

      this.monthValues.push({
        value: `${month}-${year}`,
        text: `${this.dateService.getMonthName(Number(month))} ${year}`
      });
    }

    let monthValue;

    if (data.selectedMonth) {
      monthValue = data.selectedMonth;
    } else {
      monthValue = this.monthValues[0].value;
    }

    this.dateForm = new Form([
      new FormSelect({
        name: 'month',
        control: new FormControl(monthValue, [Validators.required]),
        values: this.monthValues
      })
    ]);

    this.setMaxDate();

    setTimeout(() => {
      this.setCalendarHeight();
      this.centerContent();
    }, 1);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaBar.setSelectedMedias(this.dataService.calendar.selectedMedias);
      this.clearScheduledPosts();

      if (this.mediaBar.selectedMedias.length) {
        this.getScheduledPosts();
      }
    });
  }

  get currentMonth(): string {
    return this.dateForm.f.month.value;
  }

  get currentMonthIndex(): number {
    return this.monthValues.findIndex((value: { value: string; text: string }) => value.value === this.currentMonth);
  }

  get scheduledPostsIds(): {} {
    if (this.scheduledPosts && Object.keys(this.scheduledPosts).length) {
      const scheduledPostsIds = {};

      for (const key in this.scheduledPosts) {
        scheduledPostsIds[key] = this.scheduledPosts[key].map((post: any) => post._id);
      }

      return scheduledPostsIds;
    }

    return [];
  }

  get onGetScheduledPosts(): boolean {
    return this.onGetFacebookScheduledPosts || this.onGetInstagramScheduledPosts || this.onGetTwitterScheduledPosts;
  }

  getScheduledPosts() {
    const selectedFacebookPages = this.mediaBar.selectedMedias.filter((media: Media) => media.type === 'facebook');
    const selectedInstagramAccounts = this.mediaBar.selectedMedias.filter((media: Media) => media.type === 'instagram');
    const selectedTwitterAccounts = this.mediaBar.selectedMedias.filter((media: Media) => media.type === 'twitter');

    if (selectedFacebookPages.length) {
      if (this.getFacebookScheduledPostsSubscription && this.getFacebookScheduledPostsSubscription.closed === false) {
        this.getFacebookScheduledPostsSubscription.unsubscribe();
      }

      this.onGetFacebookScheduledPosts = true;

      this.getFacebookScheduledPostsSubscription = this.facebookService
        .getScheduledPost(selectedFacebookPages, this.currentMonth)
        .subscribe((posts: any[]) => {
          this.updateScheduledPosts(posts, 'facebook');
          this.onGetFacebookScheduledPosts = false;
        });
    }

    if (selectedInstagramAccounts.length) {
      if (this.getInstagramScheduledPostsSubscription && this.getInstagramScheduledPostsSubscription.closed === false) {
        this.getInstagramScheduledPostsSubscription.unsubscribe();
      }

      this.onGetInstagramScheduledPosts = true;

      this.getInstagramScheduledPostsSubscription = this.instagramService
        .getScheduledPost(selectedInstagramAccounts, this.currentMonth)
        .subscribe((posts: any[]) => {
          this.updateScheduledPosts(posts, 'instagram');
          this.onGetInstagramScheduledPosts = false;
        });
    }

    if (selectedTwitterAccounts.length) {
      if (this.getTwitterScheduledPostsSubscription && this.getTwitterScheduledPostsSubscription.closed === false) {
        this.getTwitterScheduledPostsSubscription.unsubscribe();
      }

      this.onGetTwitterScheduledPosts = true;

      this.getTwitterScheduledPostsSubscription = this.twitterService
        .getScheduledPost(selectedTwitterAccounts, this.currentMonth)
        .subscribe((posts: any[]) => {
          this.updateScheduledPosts(posts, 'twitter');
          this.onGetTwitterScheduledPosts = false;
        });
    }

    if (
      selectedFacebookPages.length === 0 &&
      selectedInstagramAccounts.length === 0 &&
      selectedTwitterAccounts.length === 0
    ) {
      this.scheduledPosts = {};
    }
  }

  setMaxDate() {
    this.maxDate = (this.toolsService.moment({ date: this.currentMonth, format: 'MM-YYYY' }) as Moment)
      .endOf('month')
      .get('date');
  }

  setCalendarHeight() {
    const windowHeight = $(window).height();
    const headerEl = $('header.app-header');
    const calendarEl = $('app-calendar #this-calendar');
    const casesEls = calendarEl.find('.this-case');
    const caseElHeader = calendarEl.find('.this-case:first-child > div:first-child');
    const calendarHeaderEl = $('app-calendar #this-calendar-header');
    const calendarContentEl = $('app-calendar #this-calendar-content');
    const calendarElMarginTop = Number(calendarEl.css('margin-top').slice(0, -2));
    const calendarElMarginBottom = Number(calendarEl.css('margin-bottom').slice(0, -2));
    const newHeight =
      windowHeight - headerEl.height() - calendarHeaderEl.height() - calendarElMarginTop - calendarElMarginBottom;
    const newCaseHeight = newHeight / 5;
    const newCaseContentHeight = newCaseHeight - caseElHeader.height();

    calendarContentEl.css('height', `${newHeight}px`);

    for (const caseEl of casesEls) {
      $(caseEl).css('height', `${newCaseHeight}px`);
      $(caseEl).find('> div:last-child').css('height', `${newCaseContentHeight}px`);
    }
  }

  updateScheduledPosts(posts: any[], type: string) {
    posts.map((post: any) => {
      post.scheduledDate = this.dateService.getDateDDMMYYYYHHMMFromTimestamp(post.scheduledDate);
      return post;
    });

    const days = Array.from(new Set(posts.map((post: any) => Number(post.scheduledDate.split('-')[0]))));

    for (const day of days) {
      const postsToAdd = posts
        .filter((post: any) => Number(post.scheduledDate.split('-')[0]) === day)
        .filter(
          (post: any) =>
            this.scheduledPostsIds[day] === undefined || this.scheduledPostsIds[day].includes(post._id) === false
        )
        .map((post: any) => {
          const scheduledTime = post.scheduledDate.split(' ')[1];
          const media = this.mediaBar.selectedMedias.find((media: any) => post.mediaId === media._id);

          post.type = type;

          if (media) {
            post.image = media.image;
          }

          if (this.appService.lang === 'en') {
            post.scheduledTime = this.dateService.get12HourTime(scheduledTime);
          } else {
            post.scheduledTime = scheduledTime;
          }

          return post;
        });

      if (this.scheduledPosts[day] === undefined) {
        this.scheduledPosts[day] = [];
      }

      for (const post of postsToAdd) {
        if (
          this.scheduledPosts[day].find((currentPost: any) => JSON.stringify(currentPost) === JSON.stringify(post)) ===
          undefined
        ) {
          this.scheduledPosts[day].push(post);
        }
      }

      this.scheduledPosts[day].sort((post1: any, post2: any) => {
        const timePost1 = post1.scheduledDate.split(' ')[1].split(':');
        const timePost2 = post2.scheduledDate.split(' ')[1].split(':');
        const hourPost1 = timePost1[0];
        const minutePost1 = timePost1[1];
        const hourPost2 = timePost2[0];
        const minutePost2 = timePost2[1];

        return hourPost1 > hourPost2 || (hourPost1 === hourPost2 && minutePost1 > minutePost2);
      });
    }

    for (const day in this.scheduledPosts) {
      this.scheduledPosts[day] = this.scheduledPosts[day].filter((post: any) => {
        const dateArray = post.scheduledDate.split(' ')[0].split('-');
        const month = `${dateArray[1]}-${dateArray[2]}`;

        return month === this.currentMonth && this.mediaBar.selectedIds.includes(post.mediaId);
      });
    }

    // Save calendar state
    this.dataService.calendar = {
      selectedMedias: this.mediaBar.selectedMedias,
      scheduledPosts: this.scheduledPosts,
      selectedMonth: this.currentMonth
    };

    this.dataService.saveCalendar();
  }

  centerContent() {
    const appContentEl = $('app-calendar .app-content');
    const mediaBarWidth = appContentEl.find('> div:first-child').width();
    const publisherWidth = appContentEl.find('> div:last-child').width();
    const windowWidth = $(window).width();
    let marginLeft = windowWidth / 2 - publisherWidth / 2 - mediaBarWidth;

    if (marginLeft < 0) {
      marginLeft = 0;
    }

    appContentEl.css('margin-left', `${marginLeft}px`);
  }

  clearScheduledPosts() {
    for (const day in this.scheduledPosts) {
      this.scheduledPosts[day] = this.scheduledPosts[day].filter((post: any) =>
        this.mediaBar.selectedIds.includes(post.mediaId)
      );
    }
  }

  onPostClick(scheduledPost: any) {
    setTimeout(() => {
      const media = this.mediaBar.selectedMedias.find((media: Media) => media._id === scheduledPost.mediaId);
      const date = scheduledPost.scheduledDate;
      let message;
      let medias;

      if (scheduledPost.message) {
        ({ message } = scheduledPost);
      } else {
        message = '';
      }

      if (scheduledPost.medias) {
        ({ medias } = scheduledPost);
      } else if (scheduledPost.mediaUrl) {
        medias = [scheduledPost.mediaUrl];
      } else {
        medias = [];
      }

      this.selectedPost = {
        media,
        date,
        message,
        medias,
        haveVideo: medias.length === 1 && mime.getType(medias[0]).includes('video')
      };

      if ($('ngb-popover-window').length) {
        scheduledPost.selected = true;
      }
    });
  }

  onPostPopoverHidden() {
    for (const day in this.scheduledPosts) {
      for (const post of this.scheduledPosts[day]) {
        post.selected = false;
      }
    }

    this.selectedPost = null;
  }

  onMonthChange() {
    this.setMaxDate();
    this.getScheduledPosts();
  }

  onPreviousMonthClick() {
    this.dateForm.f.month.setValue(this.monthValues[this.currentMonthIndex - 1].value);
  }

  onNextMonthClick() {
    this.dateForm.f.month.setValue(this.monthValues[this.currentMonthIndex + 1].value);
  }

  onMediaBarChange() {
    this.getScheduledPosts();
  }

  onDeletePostClick(post: any, day: number) {
    const { type } = post;

    const sendSuccessNotification = () =>
      this.notifierService.notify('success', this.translateService.instant('CALENDAR_PAGE.SUCCESS.DELETE'));
    const sendErrorNotification = () => {
      this.notifierService.notify('error', this.translateService.instant('CALENDAR_PAGE.ERRORS.DELETE'));
      post.onDelete = false;
    };
    const removeScheduledPost = () => {
      this.scheduledPosts[day] = this.scheduledPosts[day].filter((currentPost: any) => currentPost._id !== post._id);
    };

    post.onDelete = true;

    if (type === 'facebook') {
      this.facebookService.deleteScheduledPost(post.pageId, post._id).subscribe(
        () => {
          removeScheduledPost();
          sendSuccessNotification();
        },
        () => {
          sendErrorNotification();
        }
      );
    } else if (type === 'instagram') {
      this.instagramService.deleteScheduledPost(post.accountId, post._id).subscribe(
        () => {
          removeScheduledPost();
          sendSuccessNotification();
        },
        () => {
          sendErrorNotification();
        }
      );
    } else {
      this.twitterService.deleteScheduledPost(post.accountId, post._id).subscribe(
        () => {
          removeScheduledPost();
          sendSuccessNotification();
        },
        () => {
          sendErrorNotification();
        }
      );
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.centerContent();
    this.setCalendarHeight();
  }

  @HostListener('document:mediasChange')
  onMediasChange() {
    setTimeout(() => this.clearScheduledPosts());
  }
}
