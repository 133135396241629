import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { FormSelect } from '../../models/forms/form-select.model';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class FormSelectComponent implements OnInit {
  @Input() select: FormSelect;
  @Input() hideStatus: boolean;
  @Input() hideMarginBottom: boolean;
  @Output() onChange: EventEmitter<string>;

  constructor() {
    this.onChange = new EventEmitter<string>();
  }

  ngOnInit() {}

  get errors(): (string | { value: string; translationParams: any })[] {
    const errors = [];
    const errorTypes = Object.keys(this.select.control.errors);

    for (const errorType of errorTypes) {
      errors.push(this.select.errors[errorType]);
    }

    return errors;
  }

  onValueChanged() {
    this.onChange.emit(this.select.value);
  }
}
