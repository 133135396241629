import { Injectable } from '@angular/core';

import moment, { Moment } from 'moment';

import { AppService } from '../app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  constructor(private appService: AppService) {}

  /**
   * Create and returns a moment.js date.
   *
   * Note: If there is no date and no format is specified or if format is true, the date will be
   *       automatically formatted to the global date format.
   *
   * @param [date] - An optional date.
   * @param [dateArray] - An optional date in an array formatted as [year, month, day]
   * @param [format=true] - A format to handle the specified date.
   *                        True by default, which means that it will use the default app date format.
   * @param [formatNow] - Returns the current date formatted to the global date format or the specified format.
   *
   * @returns - A moment.js date or a formatted date string.
   */

  moment(
    params: { date?: any; dateArray?: number[]; format?: boolean | string; formatNow?: boolean } = {}
  ): Moment | string {
    const { DATE_FORMAT } = this.appService.config;
    const { date, dateArray, formatNow } = params;
    let { format } = params;

    if (format === undefined) {
      format = true;
    }

    if (formatNow) {
      if (typeof format === 'string') {
        return moment().format(format);
      }

      return moment().format(DATE_FORMAT);
    }

    if (Object.keys(params).length === 0) {
      return moment();
    }

    if (dateArray) {
      dateArray[1] -= 1;

      if (format === false) {
        return moment(dateArray);
      }

      return moment(dateArray).format(DATE_FORMAT);
    }

    if (format === false) {
      return moment(date);
    }

    if (format === true) {
      if (typeof date === 'object') {
        return moment(date).format(DATE_FORMAT);
      }

      return moment(date, DATE_FORMAT);
    }

    return moment(date, format);
  }

  /**
   * Format a moment.js date to the global date format or a custom one.
   *
   * @param date - The moment.js date.
   * @param [format] - The format to use.
   *
   * @returns - The formatted date.
   */
  formatDate(date: Moment, format?: string): string {
    if (format) {
      return date.format(format);
    }

    return date.format(this.appService.config.DATE_FORMAT);
  }
}
