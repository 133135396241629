import { FormControl } from '@angular/forms';

import { FormSelect as FormSelectInterface } from '../../interfaces/forms/form-select.interface';

export class FormSelect implements FormSelectInterface {
  name: string;
  control: FormControl;
  values: { value: string; text: string }[];
  prependString?: string;
  prependIcon?: string;
  errors?: { [key: string]: string | { value: string; translationParams: any } };

  constructor(select: FormSelectInterface) {
    this.name = select.name;
    this.control = select.control;
    this.values = select.values;
    this.prependString = select.prependString;
    this.prependIcon = select.prependIcon;
    this.errors = select.errors;

    if (select.validator) {
      this.validator = select.validator;
    }
  }

  get valid(): boolean {
    return this.validator();
  }

  get value(): any {
    return this.control.value;
  }

  setValue(value: any) {
    this.control.setValue(value);
  }

  validator(): boolean {
    return this.control.valid;
  }
}
