import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-settings-update-button',
  templateUrl: './settings-update-button.component.html',
  styleUrls: ['./settings-update-button.component.css']
})
export class SettingsUpdateButtonComponent implements OnInit {
  @Input() valid: boolean;
  @Input() onUpdate: string;

  constructor() {}

  ngOnInit() {}
}
