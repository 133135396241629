import { Injectable, Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  NgbDate,
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';

import { Moment } from 'moment';

import { FormDate } from '../../models/forms/form-date.model';

import { ToolsService } from '../../services/tools/tools.service';
import { DateService } from '../../services/date/date.service';

@Injectable()
export class DateAdapter extends NgbDateAdapter<string> {
  constructor(private dateService: DateService) {
    super();
  }

  fromModel(value: string | null): NgbDateStruct | null {
    return this.dateService.getDateStruct(value);
  }

  toModel(date: NgbDateStruct | null): string | null {
    return this.dateService.getDateString(date);
  }
}

@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
  constructor(private dateService: DateService) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    return this.dateService.getDateStruct(value);
  }

  format(date: NgbDateStruct | null): string | null {
    return this.dateService.getDateString(date);
  }
}

@Injectable()
export class DatePickerTranslations extends NgbDatepickerI18n {
  constructor(private dateService: DateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.dateService.getDayShortName(weekday);
  }

  getMonthShortName(month: number): string {
    return this.dateService.getMonthName(month);
  }

  getMonthFullName(month: number): string {
    return this.dateService.getMonthName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.dateService.getDateString(date);
  }
}

@Component({
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: DatePickerTranslations },
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: NgbDateParserFormatter, useClass: DateFormatter }
  ]
})
export class FormDateComponent implements OnInit {
  @ViewChild(NgbInputDatepicker) datePicker: NgbInputDatepicker;
  @Input() date: FormDate;
  @Input() disabled: boolean;
  @Output() select: EventEmitter<NgbDateStruct>;

  model: NgbDateStruct | string;
  currentDate: NgbDateStruct;
  startDate: NgbDate;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  constructor(private toolsService: ToolsService, private dateService: DateService) {
    this.select = new EventEmitter<NgbDateStruct>();
  }

  ngOnInit() {
    if (this.date.minDate) {
      const minDate = this.toolsService.moment({ date: this.date.minDate }) as Moment;

      this.minDate = {
        day: minDate.date(),
        month: minDate.month() + 1,
        year: minDate.year()
      };
    }

    if (this.date.maxDate) {
      const maxDate = this.toolsService.moment({ date: this.date.maxDate }) as Moment;

      this.maxDate = {
        day: maxDate.date(),
        month: maxDate.month() + 1,
        year: maxDate.year()
      };
    }

    if (this.date.startDate) {
      const startDate = this.toolsService.moment({ date: this.date.startDate }) as Moment;

      this.startDate = new NgbDate(startDate.year(), startDate.month() + 1, startDate.date());
      this.currentDate = { ...this.startDate };
      this.model = this.dateService.getDateString(this.currentDate);
    }
  }

  setMinDate(date: string) {
    const minDate = this.toolsService.moment({ date }) as Moment;

    this.date.minDate = this.toolsService.formatDate(minDate);
    this.minDate = {
      year: minDate.year(),
      month: minDate.month() + 1,
      day: minDate.date()
    };
  }

  setMaxDate(date: string) {
    const maxDate = this.toolsService.moment({ date }) as Moment;

    this.date.maxDate = this.toolsService.formatDate(maxDate);
    this.maxDate = {
      year: maxDate.year(),
      month: maxDate.month() + 1,
      day: maxDate.date()
    };
  }

  onDatePickerClick() {
    if ([false, undefined].includes(this.disabled)) {
      this.datePicker.toggle();
    }
  }

  onDateSelect(selectedDate: NgbDate) {
    this.currentDate = { ...selectedDate };
    this.select.emit(this.currentDate);
  }
}
