import { Component, HostListener, OnInit, AfterViewInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Moment } from 'moment';
import getUrls from 'get-urls';

import { MediaBarComponent } from '../shared/components/media-bar/media-bar.component';
import { DateTimePickerComponent } from '../shared/components/date-time-picker/date-time-picker.component';

import { Media } from '../shared/models/medias/media.model';
import { DateTime } from '../shared/models/misc/date.model';

import { AppService } from '../shared/services/app/app.service';
import { DataService } from '../shared/services/data/data.service';
import { ToolsService } from '../shared/services/tools/tools.service';
import { FacebookService } from '../shared/services/facebook/facebook.service';
import { TwitterService } from '../shared/services/twitter/twitter.service';
import { InstagramService } from '../shared/services/instagram/instagram.service';
import { FileService } from '../shared/services/file/file.service';

declare let $: any;

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})
export class PublisherComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly FACEBOOK_CHARACTER_LIMIT: number;
  readonly FACEBOOK_PHOTOS_MAX_SIZE: number;
  readonly FACEBOOK_PHOTOS_ALLOWED_FORMATS: string[];
  readonly FACEBOOK_VIDEO_MAX_SIZE: number;
  readonly FACEBOOK_VIDEO_MAX_DURATION: number;
  readonly FACEBOOK_VIDEO_ALLOWED_FORMATS: string[];
  readonly FACEBOOK_MAX_ATTACHMENTS: number;
  readonly INSTAGRAM_CHARACTER_LIMIT: number;
  readonly INSTAGRAM_PHOTOS_MAX_SIZE: number;
  readonly INSTAGRAM_PHOTOS_ALLOWED_FORMATS: string[];
  readonly INSTAGRAM_VIDEO_MAX_SIZE: number;
  readonly INSTAGRAM_VIDEO_MAX_DURATION: number;
  readonly INSTAGRAM_VIDEO_ALLOWED_FORMATS: string[];
  readonly INSTAGRAM_MAX_ATTACHMENTS: number;
  readonly TWITTER_CHARACTER_LIMIT: number;
  readonly TWITTER_PHOTOS_MAX_SIZE: number;
  readonly TWITTER_GIF_MAX_SIZE: number;
  readonly TWITTER_PHOTOS_ALLOWED_FORMATS: string[];
  readonly TWITTER_VIDEO_MAX_SIZE: number;
  readonly TWITTER_VIDEO_MAX_DURATION: number;
  readonly TWITTER_VIDEO_ALLOWED_FORMATS: string[];
  readonly TWITTER_MAX_ATTACHMENTS: number;

  @ViewChild(MediaBarComponent) mediaBar: MediaBarComponent;
  @ViewChild(DateTimePickerComponent) dateTimePickerComponent: DateTimePickerComponent;
  @ViewChild('previewModal') previewModal: TemplateRef<any>;

  formGroup: FormGroup;
  separateMediaTypes: boolean;
  files: {
    all: any[];
    facebook: any[];
    instagram: any[];
    twitter: any[];
  };
  previewFile: { type: string; index: number };
  dateTimePickerMinDate: DateTime;
  dateTimePickerMaxDate: DateTime;
  onFacebookPublish: boolean;
  onTwitterPublish: boolean;
  onInstagramPublish: boolean;
  publishedResults: { media: string; result: string[]; nbExpected: number; nbReceived: number }[];

  constructor(
    private router: Router,
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer,
    private appService: AppService,
    private dataService: DataService,
    private toolService: ToolsService,
    private facebookService: FacebookService,
    private twitterService: TwitterService,
    private instagramService: InstagramService,
    private fileService: FileService
  ) {
    this.FACEBOOK_CHARACTER_LIMIT = 63206;
    this.FACEBOOK_PHOTOS_MAX_SIZE = 1024 ** 2 * 4; // 4 MB
    this.FACEBOOK_PHOTOS_ALLOWED_FORMATS = ['image/jpeg', 'image/bmp', 'image/png', 'image/gif', 'image/tiff'];
    this.FACEBOOK_VIDEO_MAX_SIZE = 1024 ** 2 * 10 ** 4; // 4 GB
    this.FACEBOOK_VIDEO_MAX_DURATION = 60 * 60 * 4; // 240 minutes
    this.FACEBOOK_VIDEO_ALLOWED_FORMATS = [
      'video/3gpp2',
      'video/3gpp',
      'video/ms-asf',
      'video/x-ms-asf',
      'video/x-msvideo',
      'video/x-m4v',
      'video/x-matroska',
      'video/quicktime',
      'video/mp4',
      'video/mpeg',
      'video/mp2t',
      'video/ogg',
      'video/dvd',
      'video/x-ms-wmv'
    ];
    this.FACEBOOK_MAX_ATTACHMENTS = 15;

    this.INSTAGRAM_CHARACTER_LIMIT = 2200;
    this.INSTAGRAM_PHOTOS_ALLOWED_FORMATS = ['image/jpeg'];
    this.INSTAGRAM_VIDEO_MAX_SIZE = 1024 ** 2 * 10 ** 2; // 100 MB
    this.INSTAGRAM_VIDEO_MAX_DURATION = 60; // 60 seconds
    this.INSTAGRAM_VIDEO_ALLOWED_FORMATS = ['video/mp4', 'video/quicktime'];
    this.INSTAGRAM_MAX_ATTACHMENTS = 1;

    this.TWITTER_CHARACTER_LIMIT = 280;
    this.TWITTER_PHOTOS_MAX_SIZE = 1024 ** 2 * 5; // 5 MB
    this.TWITTER_GIF_MAX_SIZE = 1024 ** 2 * 15; // 15 MB
    this.TWITTER_PHOTOS_ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    this.TWITTER_VIDEO_MAX_SIZE = 1024 ** 2 * 512; // 512 MB
    this.TWITTER_VIDEO_MAX_DURATION = 140; // 140 seconds
    this.TWITTER_VIDEO_ALLOWED_FORMATS = ['video/mp4', 'video/quicktime'];
    this.TWITTER_MAX_ATTACHMENTS = 4;
  }

  ngOnInit() {
    this.appService.setTitle('HEADER.PUBLISHER');

    const data = this.dataService.publisher;

    this.separateMediaTypes = data.separateMediaTypes;
    this.formGroup = new FormGroup({
      text: new FormControl(data.text),
      textFacebook: new FormControl(data.textFacebook),
      textInstagram: new FormControl(data.textInstagram),
      textTwitter: new FormControl(data.textTwitter),
      scheduledDate: new FormControl(data.scheduledDate),
      publishMode: new FormControl(data.publishMode)
    });
    this.files = data.files;
    this.previewFile = null;
    this.onFacebookPublish = data.onFacebookPublish;
    this.onTwitterPublish = data.onTwitterPublish;
    this.onInstagramPublish = data.onInstagramPublish;
    this.publishedResults = [];

    this.centerContent();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataService.cleanSelectedMedias(this.dataService.publisher);
      this.mediaBar.setSelectedMedias(this.dataService.publisher.selectedMedias);
      this.dataService.savePublisher();
    });
  }

  ngOnDestroy() {
    if (this.router.url !== '/') {
      this.savePublisherData();
    }
  }

  get text(): AbstractControl {
    return this.formGroup.get('text');
  }

  get textFacebook(): AbstractControl {
    return this.formGroup.get('textFacebook');
  }

  get textInstagram(): AbstractControl {
    return this.formGroup.get('textInstagram');
  }

  get textTwitter(): AbstractControl {
    return this.formGroup.get('textTwitter');
  }

  get scheduledDate(): AbstractControl {
    return this.formGroup.get('scheduledDate');
  }

  get publishMode(): AbstractControl {
    return this.formGroup.get('publishMode');
  }

  get selectedMediaTypes(): string[] {
    if (this.mediaBar) {
      const types = this.mediaBar.selectedMedias.map((media: Media) => media.type);
      const mediaTypes = [];

      if (types.includes('facebook')) {
        mediaTypes.push('facebook');
      }

      if (types.includes('instagram')) {
        mediaTypes.push('instagram');
      }

      if (types.includes('twitter')) {
        mediaTypes.push('twitter');
      }

      return mediaTypes;
    }

    return [];
  }

  get errors(): string[] {
    const errors = [];

    if (this.mediaBar && this.mediaBar.selectedMedias.length) {
      if (this.hasCombinedPhotoAndVideo) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.COMBINED_PHOTO_VIDEO',
          params: {}
        });
      }

      if (this.hasInvalidFiles) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.INVALID_FILES',
          params: {}
        });
      }

      if (this.hasMoreThanOneVideo) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.MORE_THAN_ONE_VIDEO',
          params: {}
        });
      }

      if (this.facebookHasTooManyCharacters) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.FACEBOOK.TOO_MANY_CHARACTERS',
          params: {
            maxCharacters: this.FACEBOOK_CHARACTER_LIMIT
          }
        });
      }

      if (this.twitterHasTooManyCharacters) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.TWITTER.TOO_MANY_CHARACTERS',
          params: {
            maxCharacters: this.TWITTER_CHARACTER_LIMIT
          }
        });
      }

      if (this.instagramHasTooManyCharacters) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.INSTAGRAM.TOO_MANY_CHARACTERS',
          params: {
            maxCharacters: this.INSTAGRAM_CHARACTER_LIMIT
          }
        });
      }

      if (this.facebookHasTooManyAttachments) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.FACEBOOK.TOO_MANY_ATTACHMENTS',
          params: {
            maxAttachments: this.FACEBOOK_MAX_ATTACHMENTS
          }
        });
      }

      if (this.twitterHasTooManyAttachments) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.TWITTER.TOO_MANY_ATTACHMENTS',
          params: {
            maxAttachments: this.TWITTER_MAX_ATTACHMENTS
          }
        });
      }

      if (this.instagramHasTooManyAttachments) {
        errors.push({
          msg: 'PUBLISHER_PAGE.ERRORS.INSTAGRAM.TOO_MANY_ATTACHMENTS',
          params: {
            maxAttachments: this.INSTAGRAM_MAX_ATTACHMENTS
          }
        });
      }
    }

    return errors;
  }

  get disablePublish(): boolean {
    let disable =
      this.selectedMediaTypes.length === 0 || (this.publishMode.value === 'later' && this.scheduledDateString === null);

    if (this.separateMediaTypes === false) {
      if (this.selectedMediaTypes.includes('twitter') || this.selectedMediaTypes.includes('facebook')) {
        disable = disable || (this.text.value.length === 0 && this.files.all.length === 0);
      }

      if (this.selectedMediaTypes.includes('facebook')) {
        disable = disable || this.facebookHasTooManyCharacters;
      }

      if (this.selectedMediaTypes.includes('twitter')) {
        disable = disable || this.twitterHasTooManyCharacters;
      }

      if (this.selectedMediaTypes.includes('instagram')) {
        disable = disable || this.files.all.length === 0 || this.instagramHasTooManyCharacters;
      }
    } else {
      if (this.selectedMediaTypes.includes('facebook')) {
        disable =
          disable ||
          (this.textFacebook.value.length === 0 && this.files.facebook.length === 0) ||
          this.facebookHasTooManyCharacters;
      }

      if (this.selectedMediaTypes.includes('twitter')) {
        disable =
          disable ||
          (this.textTwitter.value.length === 0 && this.files.twitter.length === 0) ||
          this.twitterHasTooManyCharacters;
      }

      if (this.selectedMediaTypes.includes('instagram')) {
        disable = disable || this.files.instagram.length === 0 || this.instagramHasTooManyCharacters;
      }
    }

    if (this.publishMode.value === 'later') {
      disable = disable || this.isScheduledDateExpired;
    }

    disable =
      disable ||
      this.hasInvalidFiles ||
      this.hasCombinedPhotoAndVideo ||
      this.hasMoreThanOneVideo ||
      this.facebookHasTooManyAttachments ||
      this.twitterHasTooManyAttachments ||
      this.instagramHasTooManyAttachments;

    return disable;
  }

  get combinedNetworksCharacterLimit(): number {
    if (this.selectedMediaTypes.includes('twitter')) {
      return this.TWITTER_CHARACTER_LIMIT;
    }

    if (this.selectedMediaTypes.includes('instagram')) {
      return this.INSTAGRAM_CHARACTER_LIMIT;
    }

    return this.FACEBOOK_CHARACTER_LIMIT;
  }

  get scheduledDateString(): string {
    if (this.dateTimePickerComponent) {
      return this.dateTimePickerComponent.dateString;
    }

    return null;
  }

  get hasCombinedPhotoAndVideo(): boolean {
    if (this.separateMediaTypes) {
      const keys = Object.keys(this.files).filter((key: string) => key !== 'all');
      let hasCombinedTypes = false;

      for (const key of keys) {
        hasCombinedTypes =
          this.files[key].find((file: any) => file.type.includes('image')) !== undefined &&
          this.files[key].find((file: any) => file.type.includes('video')) !== undefined;

        if (hasCombinedTypes) {
          break;
        }
      }

      return hasCombinedTypes;
    }

    return (
      this.files.all.find((file: any) => file.type.includes('image')) !== undefined &&
      this.files.all.find((file: any) => file.type.includes('video')) !== undefined
    );
  }

  get hasFiles(): boolean {
    if (this.separateMediaTypes) {
      return this.files.facebook.length > 0 || this.files.twitter.length > 0 || this.files.instagram.length > 0;
    }

    return this.files.all.length > 0;
  }

  get hasInvalidFiles(): boolean {
    let hasInvalidFiles;

    if (this.separateMediaTypes === false) {
      if (this.selectedMediaTypes.includes('facebook')) {
        hasInvalidFiles = this.hasInvalidFilesInType('facebook', this.files.all) || hasInvalidFiles;
      }

      if (this.selectedMediaTypes.includes('instagram')) {
        hasInvalidFiles = this.hasInvalidFilesInType('instagram', this.files.all) || hasInvalidFiles;
      }

      if (this.selectedMediaTypes.includes('twitter')) {
        hasInvalidFiles = this.hasInvalidFilesInType('twitter', this.files.all) || hasInvalidFiles;
      }
    } else {
      if (this.selectedMediaTypes.includes('facebook')) {
        hasInvalidFiles = this.hasInvalidFilesInType('facebook', this.files.facebook) || hasInvalidFiles;
      }

      if (this.selectedMediaTypes.includes('instagram')) {
        hasInvalidFiles = this.hasInvalidFilesInType('instagram', this.files.instagram) || hasInvalidFiles;
      }

      if (this.selectedMediaTypes.includes('twitter')) {
        hasInvalidFiles = this.hasInvalidFilesInType('twitter', this.files.twitter) || hasInvalidFiles;
      }
    }

    return hasInvalidFiles;
  }

  get hasMoreThanOneVideo(): boolean {
    if (this.separateMediaTypes) {
      const keys = Object.keys(this.files).filter((key: string) => key !== 'all');
      let hasMultipleVideos = false;

      for (const key of keys) {
        hasMultipleVideos = this.files[key].filter((file: any) => file.type.includes('video')).length > 1;

        if (hasMultipleVideos) {
          break;
        }
      }

      return hasMultipleVideos;
    }

    return this.files.all.filter((file: any) => file.type.includes('video')).length > 1;
  }

  get facebookHasTooManyCharacters(): boolean {
    if (this.mediaBar && this.mediaBar.selectedMedias.find((media: Media) => media.type === 'facebook')) {
      if (this.separateMediaTypes) {
        return this.textFacebook.value.length > this.FACEBOOK_CHARACTER_LIMIT;
      }

      return this.text.value.length > this.FACEBOOK_CHARACTER_LIMIT;
    }

    return false;
  }

  get twitterHasTooManyCharacters(): boolean {
    if (this.mediaBar && this.mediaBar.selectedMedias.find((media: Media) => media.type === 'twitter')) {
      if (this.separateMediaTypes) {
        return this.textTwitter.value.length > this.TWITTER_CHARACTER_LIMIT;
      }

      return this.text.value.length > this.TWITTER_CHARACTER_LIMIT;
    }

    return false;
  }

  get instagramHasTooManyCharacters(): boolean {
    if (this.mediaBar && this.mediaBar.selectedMedias.find((media: Media) => media.type === 'instagram')) {
      if (this.separateMediaTypes) {
        return this.textInstagram.value.length > this.INSTAGRAM_CHARACTER_LIMIT;
      }

      return this.text.value.length > this.INSTAGRAM_CHARACTER_LIMIT;
    }

    return false;
  }

  get facebookHasTooManyAttachments(): boolean {
    if (this.mediaBar && this.mediaBar.selectedMedias.find((media: Media) => media.type === 'facebook')) {
      if (this.separateMediaTypes) {
        return this.files.facebook.length > this.FACEBOOK_MAX_ATTACHMENTS;
      }

      return this.files.all.length > this.FACEBOOK_MAX_ATTACHMENTS;
    }

    return false;
  }

  get twitterHasTooManyAttachments(): boolean {
    if (this.mediaBar && this.mediaBar.selectedMedias.find((media: Media) => media.type === 'twitter')) {
      if (this.separateMediaTypes) {
        return this.files.twitter.length > this.TWITTER_MAX_ATTACHMENTS;
      }

      return this.files.all.length > this.TWITTER_MAX_ATTACHMENTS;
    }

    return false;
  }

  get instagramHasTooManyAttachments(): boolean {
    if (this.mediaBar && this.mediaBar.selectedMedias.find((media: Media) => media.type === 'instagram')) {
      if (this.separateMediaTypes) {
        return this.files.instagram.length > this.INSTAGRAM_MAX_ATTACHMENTS;
      }

      return this.files.all.length > this.INSTAGRAM_MAX_ATTACHMENTS;
    }

    return false;
  }

  get isScheduledDateExpired(): boolean {
    const format = `${this.appService.config.DATE_FORMAT} HH:mm`;
    const currentDate = this.toolService.moment() as Moment;
    const scheduledDate = this.toolService.moment({
      date: this.dateTimePickerComponent.dateTimeDDMMYYYYHHSS,
      format
    }) as Moment;

    return currentDate.isAfter(scheduledDate);
  }

  get onFileUpload(): boolean {
    if (this.separateMediaTypes) {
      return (
        this.files.facebook.find((file: any) => file.onUpload === true) !== undefined ||
        this.files.twitter.find((file: any) => file.onUpload === true) !== undefined ||
        this.files.instagram.find((file: any) => file.onUpload === true) !== undefined
      );
    }

    return this.files.all.find((file: any) => file.onUpload === true) !== undefined;
  }

  get onPublish(): boolean {
    return this.onFileUpload || this.onFacebookPublish || this.onTwitterPublish || this.onInstagramPublish;
  }

  getContext(type: string) {
    if (type === 'all') {
      return {
        type,
        formControl: 'text'
      };
    }

    return {
      type,
      formControl: `text${type.charAt(0).toUpperCase()}${type.slice(1)}`
    };
  }

  setPreviewImageMaxHeight() {
    const maxHeight = `${$(window).height() - 200}px`;
    $('.this-preview-modal img').css('max-height', maxHeight);
  }

  setDateTimePickerPosition() {
    const dateTimePickerEl = $('app-date-time-picker #this');
    const publisherEl = $('app-publisher #this-publisher');
    const right = `${$(window).width() - publisherEl[0].getBoundingClientRect().right}px`;

    $(dateTimePickerEl).css('left', 'unset');
    $(dateTimePickerEl).css('right', right);
  }

  hasInvalidFilesInType(type: string, files: any): boolean {
    let hasInvalidFile = false;

    for (const file of files) {
      if (hasInvalidFile === false) {
        switch (type) {
          case 'facebook':
            hasInvalidFile = this.isFacebookFileInvalid(file);
            break;
          case 'twitter':
            hasInvalidFile = this.isTwitterFileInvalid(file);
            break;
          case 'instagram':
            hasInvalidFile = this.isInstagramFileInvalid(file);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case 'facebook':
            this.isFacebookFileInvalid(file);
            break;
          case 'twitter':
            this.isTwitterFileInvalid(file);
            break;
          case 'instagram':
            this.isInstagramFileInvalid(file);
            break;
          default:
            break;
        }
      }
    }

    return hasInvalidFile;
  }

  isFacebookFileInvalid(file: any): boolean {
    if (file.type.includes('image')) {
      if (this.FACEBOOK_PHOTOS_ALLOWED_FORMATS.includes(file.type) === false) {
        file.error = 'PUBLISHER_PAGE.ERRORS.FACEBOOK.PHOTO_FORMAT';
      }

      if (file.size > this.FACEBOOK_PHOTOS_MAX_SIZE) {
        file.error = 'PUBLISHER_PAGE.ERRORS.FACEBOOK.PHOTO_SIZE';
      }
    }

    if (file.type.includes('video')) {
      if (this.FACEBOOK_VIDEO_ALLOWED_FORMATS.includes(file.type) === false) {
        file.error = 'PUBLISHER_PAGE.ERRORS.FACEBOOK.VIDEO_FORMAT';
      }

      if (file.size > this.FACEBOOK_VIDEO_MAX_SIZE) {
        file.error = 'PUBLISHER_PAGE.ERRORS.FACEBOOK.VIDEO_SIZE';
      }

      if (file.duration > this.FACEBOOK_VIDEO_MAX_DURATION) {
        file.error = 'PUBLISHER_PAGE.ERRORS.FACEBOOK.VIDEO_DURATION';
      }
    }

    return file.error !== undefined;
  }

  isInstagramFileInvalid(file: any): boolean {
    if (file.type.includes('image')) {
      if (this.INSTAGRAM_PHOTOS_ALLOWED_FORMATS.includes(file.type) === false) {
        file.error = 'PUBLISHER_PAGE.ERRORS.INSTAGRAM.PHOTO_FORMAT';
      }
    }

    if (file.type.includes('video')) {
      if (this.INSTAGRAM_VIDEO_ALLOWED_FORMATS.includes(file.type) === false) {
        file.error = 'PUBLISHER_PAGE.ERRORS.INSTAGRAM.VIDEO_FORMAT';
      }

      if (file.size > this.INSTAGRAM_VIDEO_MAX_SIZE) {
        file.error = 'PUBLISHER_PAGE.ERRORS.INSTAGRAM.VIDEO_SIZE';
      }

      if (file.duration > this.INSTAGRAM_VIDEO_MAX_DURATION) {
        file.error = 'PUBLISHER_PAGE.ERRORS.INSTAGRAM.VIDEO_DURATION';
      }
    }

    return file.error !== undefined;
  }

  isTwitterFileInvalid(file: any): boolean {
    if (file.type.includes('image')) {
      if (this.TWITTER_PHOTOS_ALLOWED_FORMATS.includes(file.type) === false) {
        file.error = 'PUBLISHER_PAGE.ERRORS.TWITTER.PHOTO_FORMAT';
      }

      if (file.type !== 'image/gif' && file.size > this.TWITTER_PHOTOS_MAX_SIZE) {
        file.error = 'PUBLISHER_PAGE.ERRORS.TWITTER.PHOTO_SIZE';
      }

      if (file.type === 'image/gif' && file.size > this.TWITTER_GIF_MAX_SIZE) {
        file.error = 'PUBLISHER_PAGE.ERRORS.TWITTER.GIF_SIZE';
      }
    }

    if (file.type.includes('video')) {
      if (this.TWITTER_VIDEO_ALLOWED_FORMATS.includes(file.type) === false) {
        file.error = 'PUBLISHER_PAGE.ERRORS.TWITTER.VIDEO_FORMAT';
      }

      if (file.size > this.TWITTER_VIDEO_MAX_SIZE) {
        file.error = 'PUBLISHER_PAGE.ERRORS.TWITTER.VIDEO_SIZE';
      }

      if (file.duration > this.TWITTER_VIDEO_MAX_DURATION) {
        file.error = 'PUBLISHER_PAGE.ERRORS.TWITTER.VIDEO_DURATION';
      }
    }

    return file.error !== undefined;
  }

  centerContent() {
    const appContentEl = $('app-publisher .app-content');
    const mediaBarWidth = appContentEl.find('> div:first-child').width();
    const publisherWidth = appContentEl.find('> div:last-child').width();
    const windowWidth = $(window).width();
    let marginLeft = windowWidth / 2 - publisherWidth / 2 - mediaBarWidth;

    if (marginLeft < 0) {
      marginLeft = 0;
    }

    appContentEl.css('margin-left', `${marginLeft}px`);
  }

  uploadFile(file: any) {
    file.onUpload = true;

    if (file.uploadSubscription) {
      file.uploadSubscription.unsubscribe();
    }

    file.uploadSubscription = this.fileService.upload(file.file).subscribe(
      (event: HttpProgressEvent) => {
        const type = <HttpEventType>event.type;

        switch (type) {
          case HttpEventType.UploadProgress:
            file.uploadProgress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            file.uploadCompleted = true;
            file.url = (event as any).body.location;
            break;
          default:
            break;
        }
      },
      () => {
        this.uploadFile(file);
      },
      () => {
        file.onUpload = false;

        if (this.onFileUpload === false) {
          this.publish();
        }
      }
    );
  }

  uploadFiles() {
    let files = [];

    if (this.separateMediaTypes) {
      if (this.selectedMediaTypes.includes('facebook') && this.files.facebook.length) {
        files.push(...this.files.facebook);
      }

      if (this.selectedMediaTypes.includes('twitter') && this.files.twitter.length) {
        files.push(...this.files.twitter);
      }

      if (this.selectedMediaTypes.includes('instagram')) {
        files.push(...this.files.instagram);
      }
    } else if (this.files.all.length) {
      files.push(...this.files.all);
    }

    files = files.filter((file: any) => file.uploadCompleted === undefined);

    if (files.length) {
      for (const file of files) {
        this.uploadFile(file);
      }
    } else {
      this.publish();
    }
  }

  publish() {
    if (this.publishMode.value === 'later' && this.isScheduledDateExpired) {
      return;
    }

    if (this.selectedMediaTypes.includes('facebook')) {
      const pages = this.mediaBar.selectedMedias.filter((media: Media) => media.type === 'facebook');
      const params: any = {};
      const result = { media: 'facebook', result: [], nbExpected: pages.length, nbReceived: 0 };
      let message;
      let video;

      const pushFacebookResult = () => {
        this.publishedResults.push(result);
        this.onFacebookPublish = false;

        this.afterPublish();
      };

      if (this.separateMediaTypes) {
        message = this.textFacebook.value;

        if (this.files.facebook.length === 1 && this.files.facebook[0].type.includes('video')) {
          [video] = this.files.facebook;
        }
      } else {
        message = this.text.value;

        if (this.files.all.length === 1 && this.files.all[0].type.includes('video')) {
          [video] = this.files.all;
        }
      }

      if (this.publishMode.value === 'later') {
        if (video) {
          params.medias = [video.url];
        } else if (this.separateMediaTypes) {
          params.medias = this.files.facebook.map((file: any) => file.url);
        } else {
          params.medias = this.files.all.map((file: any) => file.url);
        }

        if (message) {
          params.message = message;

          if (params.medias.length === 0) {
            params.link = getUrls(message).values().next().value;
          }
        } else {
          params.message = '';
        }

        params.scheduledDate = this.dateTimePickerComponent.dateTimestamp;
        params.accountNames = {};

        for (const page of pages) {
          params.accountNames[page._id] = page.name;
        }

        this.onFacebookPublish = true;

        this.facebookService.schedule(params, pages).subscribe(
          (data: any) => {
            result.result = data;
            result.nbReceived = Object.keys(data).length;
          },
          () => pushFacebookResult(),
          () => pushFacebookResult()
        );
      } else if (video) {
        params.url = video.url;

        if (message) {
          params.description = message;
        }

        this.onFacebookPublish = true;

        this.facebookService.publishVideo(params, pages).subscribe(
          (data: string[]) => {
            result.result = data;
            result.nbReceived = Object.keys(data).length;
          },
          () => pushFacebookResult(),
          () => pushFacebookResult()
        );
      } else {
        if (this.separateMediaTypes) {
          params.medias = this.files.facebook.map((file: any) => file.url);
        } else {
          params.medias = this.files.all.map((file: any) => file.url);
        }

        if (message) {
          params.message = message;
          params.link = getUrls(message).values().next().value;
        }

        if (this.publishMode.value === 'later') {
          params.scheduledDate = this.dateTimePickerComponent.dateTimestamp;
        }

        this.onFacebookPublish = true;

        this.facebookService.publish(params, pages).subscribe(
          (data: string[]) => {
            result.result = data;
            result.nbReceived = data.length;
          },
          () => pushFacebookResult(),
          () => pushFacebookResult()
        );
      }
    }

    if (this.selectedMediaTypes.includes('twitter')) {
      const accounts = this.mediaBar.selectedMedias.filter((media: Media) => media.type === 'twitter');
      const params: any = {};
      const result = { media: 'twitter', result: [], nbExpected: accounts.length, nbReceived: 0 };

      const pushTwitterResult = () => {
        this.publishedResults.push(result);
        this.onTwitterPublish = false;

        this.afterPublish();
      };

      if (this.separateMediaTypes) {
        params.message = this.textTwitter.value;
        params.medias = this.files.twitter.map((file: any) => file.url);
      } else {
        params.message = this.text.value;
        params.medias = this.files.all.map((file: any) => file.url);
      }

      if (this.publishMode.value === 'later') {
        params.scheduledDate = this.dateTimePickerComponent.dateTimestamp;
        params.accountNames = {};

        for (const account of accounts) {
          params.accountNames[account._id] = account.name;
        }
      }

      this.onTwitterPublish = true;

      if (this.publishMode.value === 'now') {
        this.twitterService.publish(params, accounts).subscribe(
          (data: string[]) => {
            result.result = data;
            result.nbReceived = data.length;
          },
          () => pushTwitterResult(),
          () => pushTwitterResult()
        );
      } else {
        this.twitterService.schedule(params, accounts).subscribe(
          (data: any) => {
            result.result = data;
            result.nbReceived = Object.keys(data).length;
          },
          () => pushTwitterResult(),
          () => pushTwitterResult()
        );
      }
    }

    if (this.selectedMediaTypes.includes('instagram')) {
      const accounts = this.mediaBar.selectedMedias.filter((media: Media) => media.type === 'instagram');
      const params: any = {};
      const result = { media: 'instagram', result: [], nbExpected: accounts.length, nbReceived: 0 };
      let file;

      const pushInstagramResult = () => {
        this.publishedResults.push(result);
        this.onInstagramPublish = false;

        this.afterPublish();
      };

      if (this.separateMediaTypes) {
        [file] = this.files.instagram;
        params.caption = this.textInstagram.value;
      } else {
        [file] = this.files.all;
        params.caption = this.text.value;
      }

      params.mediaUrl = file.url;

      if (file.type.includes('image')) {
        params.type = 'photo';
      } else {
        params.type = 'video';
      }

      if (this.publishMode.value === 'later') {
        params.scheduledDate = this.dateTimePickerComponent.dateTimestamp;
        params.accountNames = {};

        for (const account of accounts) {
          params.accountNames[account._id] = account.name;
        }
      }

      this.onInstagramPublish = true;

      if (this.publishMode.value === 'now') {
        this.instagramService.publish(params, accounts).subscribe(
          (data: string[]) => {
            result.result = data;
            result.nbReceived = Object.keys(data).length;
          },
          () => pushInstagramResult(),
          () => pushInstagramResult()
        );
      } else {
        this.instagramService.schedule(params, accounts).subscribe(
          (data: string[]) => {
            result.result = data;
            result.nbReceived = Object.keys(data).length;
          },
          () => pushInstagramResult(),
          () => pushInstagramResult()
        );
      }
    }
  }

  afterPublish() {
    if (this.onFacebookPublish || this.onTwitterPublish || this.onInstagramPublish) {
      return;
    }

    const publishMode = this.publishMode.value;
    const nbExpected = this.publishedResults.reduce(
      (accNbExpected: number, result: any) => accNbExpected + result.nbExpected,
      0
    );
    const nbReceived = this.publishedResults.reduce(
      (accNbReceived: number, result: any) => accNbReceived + result.nbReceived,
      0
    );

    if (nbReceived === 0) {
      if (publishMode === 'now') {
        this.notifierService.notify('error', this.translateService.instant('PUBLISHER_PAGE.ERRORS.PUBLISHED'));
      } else {
        this.notifierService.notify('error', this.translateService.instant('PUBLISHER_PAGE.ERRORS.SCHEDULED'));
      }
    } else if (nbExpected === nbReceived) {
      if (publishMode === 'now') {
        this.notifierService.notify('success', this.translateService.instant('PUBLISHER_PAGE.SUCCESS.PUBLISHED'));
      } else {
        this.notifierService.notify('success', this.translateService.instant('PUBLISHER_PAGE.SUCCESS.SCHEDULED'));
      }

      this.modalService.dismissAll();

      this.text.setValue('');
      this.textFacebook.setValue('');
      this.textInstagram.setValue('');
      this.textTwitter.setValue('');
      this.publishMode.setValue('now');
      this.scheduledDate.setValue('');

      this.previewFile = null;
      this.files = { all: [], facebook: [], instagram: [], twitter: [] };

      this.dateTimePickerComponent.reset();
      this.savePublisherData();
    } else if (publishMode === 'now') {
      this.notifierService.notify(
        'success',
        this.translateService.instant('PUBLISHER_PAGE.SUCCESS.PUBLISHED_PARTIALLY')
      );
    } else {
      this.notifierService.notify(
        'success',
        this.translateService.instant('PUBLISHER_PAGE.SUCCESS.SCHEDULED_PARTIALLY')
      );
    }
  }

  savePublisherData() {
    if (!this.dataService.publisher) {
      this.dataService.initPublisher();
    }

    if (this.mediaBar) {
      this.dataService.publisher.selectedMedias = this.mediaBar.selectedMedias;
    }

    this.dataService.publisher.separateMediaTypes = this.separateMediaTypes;
    this.dataService.publisher.text = this.text.value;
    this.dataService.publisher.textFacebook = this.textFacebook.value;
    this.dataService.publisher.textInstagram = this.textInstagram.value;
    this.dataService.publisher.textTwitter = this.textTwitter.value;
    this.dataService.publisher.scheduledDate = this.scheduledDate.value;
    this.dataService.publisher.publishMode = this.publishMode.value;
    this.dataService.publisher.files = this.files;
    this.dataService.publisher.onFacebookPublish = this.onFacebookPublish;
    this.dataService.publisher.onTwitterPublish = this.onTwitterPublish;
    this.dataService.publisher.onInstagramPublish = this.onInstagramPublish;

    this.dataService.savePublisher();
  }

  onCustomByNetworkClick() {
    this.separateMediaTypes = !this.separateMediaTypes;

    if (this.files.all.length) {
      this.files.all = this.files.all.map((file: any) => {
        if (file.error) {
          delete file.error;
        }

        return file;
      });
    }

    if (this.separateMediaTypes === true) {
      for (const mediaType of this.selectedMediaTypes) {
        const formGroup = this.formGroup.get(this.getContext(mediaType).formControl);
        formGroup.setValue(this.text.value);

        this.files[mediaType] = [];

        if (this.files.all.length) {
          this.files[mediaType] = JSON.parse(JSON.stringify(this.files.all));

          for (let i = 0; i < this.files.all.length; i += 1) {
            this.files[mediaType][i].file = this.files.all[i].file;
          }
        }
      }
    }
  }

  onMediaBarChange() {
    if (this.separateMediaTypes) {
      if (this.selectedMediaTypes.length === 1) {
        const [mediaType] = this.selectedMediaTypes;
        this.text.setValue(this.formGroup.get(this.getContext(mediaType).formControl).value);
      }

      if (this.selectedMediaTypes.length <= 1) {
        this.separateMediaTypes = false;
      }
    }

    // Reset errors
    const keys = Object.keys(this.files);

    for (const key of keys) {
      if (this.files[key].length) {
        for (const file of this.files[key]) {
          if (file.error) {
            delete file.error;
          }
        }
      }
    }

    this.dataService.publisher.selectedMedias = this.mediaBar.selectedMedias;
    this.dataService.savePublisher();
  }

  onAttachmentsPreviewWheel(type: string, event: WheelEvent) {
    const [attachmentsPreviewEl] = $(`.this-attachments-preview-${type}`);

    attachmentsPreviewEl.scrollLeft -= -event.deltaY;
    event.preventDefault();
  }

  onFileUploadClick(mediaType: string = 'all') {
    const reader = new FileReader();
    let files: Blob[];
    let currentFile: Blob;
    let index = 0;

    if (mediaType === 'all') {
      files = $('#this-file-input')[0].files;
    } else {
      files = $(`#this-${mediaType}-file-input`)[0].files;
    }

    currentFile = files[index];

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const { type, size } = currentFile;
      let data: string | ArrayBufferLike;
      let file: any;

      const pushData = () => {
        this.files[mediaType].push(file);

        if (index < files.length) {
          currentFile = files[index];

          if (currentFile.type.includes('video')) {
            reader.readAsArrayBuffer(currentFile);
          } else {
            reader.readAsDataURL(currentFile);
          }
        }
      };

      index += 1;

      if (currentFile.type.includes('video')) {
        data = <ArrayBufferLike>event.target.result;

        const [videoEl] = $('<video></video>');
        const [canvasEl] = $('<canvas></canvas>');
        const context = canvasEl.getContext('2d');
        const img = document.createElement('img');
        const videoBlob = new Blob([new Uint8Array(data)], { type });
        const url = window.URL.createObjectURL(videoBlob);
        let videoWidth;
        let videoHeight;

        file = {
          file: currentFile,
          url: this.domSanitizer.bypassSecurityTrustUrl(url),
          size,
          type
        };

        // Create thumbnail
        videoEl.ondurationchange = () => {
          file.duration = videoEl.duration;

          videoWidth = videoEl.videoWidth;
          videoHeight = videoEl.videoHeight;

          canvasEl.width = videoWidth;
          canvasEl.height = videoHeight;

          videoEl.currentTime = videoEl.duration / 4;
        };

        videoEl.onseeked = () => {
          context.drawImage(videoEl, 0, 0, videoWidth, videoHeight);

          img.src = canvasEl.toDataURL();
          file.thumbnail = img.src;

          pushData();
        };

        videoEl.src = url;
      } else {
        data = <string>event.target.result;
        file = {
          file: currentFile,
          data,
          size,
          type
        };

        pushData();
      }
    };

    if (currentFile.type.includes('video')) {
      reader.readAsArrayBuffer(currentFile);
    } else {
      reader.readAsDataURL(currentFile);
    }
  }

  onFileClick(event: any, mediaType: string, index: number) {
    if (event.target.className !== 'fas fa-times-circle') {
      this.previewFile = { type: mediaType, index };
      this.modalService
        .open(this.previewModal, { windowClass: 'this-preview-modal' })
        .result.finally(() => (this.previewFile = null));

      setTimeout(() => this.setPreviewImageMaxHeight());
    }
  }

  onFilePreviousClick() {
    if (this.previewFile.index === 0) {
      this.previewFile.index = this.files[this.previewFile.type].length - 1;
    } else {
      this.previewFile.index -= 1;
    }
  }

  onFileNextClick() {
    if (this.previewFile.index === this.files[this.previewFile.type].length - 1) {
      this.previewFile.index = 0;
    } else {
      this.previewFile.index += 1;
    }
  }

  onFileRemoveClick(mediaType: string, index: number) {
    const file = this.files[mediaType][index];

    if (file.uploadSubscription && !file.uploadSubscription.isStopped) {
      file.uploadSubscription.unsubscribe();
    }

    this.files[mediaType].splice(index, 1);
  }

  onScheduledDateClick() {
    if (this.onPublish) {
      return;
    }

    if (this.dateTimePickerComponent.show === false) {
      const date = this.toolService.moment() as Moment;
      const dateMax = (this.toolService.moment() as Moment).add(6, 'months');

      this.dateTimePickerMinDate = {
        day: date.date(),
        month: date.month() + 1,
        year: date.year(),
        hour: date.hours(),
        minute: date.minutes() + 10
      };
      this.dateTimePickerMaxDate = {
        day: dateMax.date(),
        month: dateMax.month() + 1,
        year: dateMax.year(),
        hour: 23,
        minute: 50
      };

      if (this.dateTimePickerMinDate.minute >= 60) {
        this.dateTimePickerMinDate.hour += 1;
        this.dateTimePickerMinDate.minute = 10;
      }

      setTimeout(() => {
        this.dateTimePickerComponent.open();
        this.setDateTimePickerPosition();
      });
    } else {
      this.dateTimePickerComponent.close();
    }
  }

  onPublishClick() {
    this.publishedResults = [];

    if (this.hasFiles) {
      this.uploadFiles();
    } else {
      this.publish();
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.centerContent();

    if (this.previewFile !== null) {
      this.setPreviewImageMaxHeight();
    }

    if (this.dateTimePickerComponent && this.dateTimePickerComponent.show) {
      this.setDateTimePickerPosition();
    }
  }
}
