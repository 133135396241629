import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppService } from 'src/app/shared/services/app/app.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss']
})
export class RegisterConfirmComponent implements OnInit {
  onRegisterConfirm: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.appService.setTitle('REGISTER_CONFIRM_PAGE.TITLE');

    const { confirmationToken } = this.activatedRoute.snapshot.params;
    const { lang } = this.activatedRoute.snapshot.queryParams;

    if (lang) {
      this.appService.setLang(lang);
    }

    this.onRegisterConfirm = 'true';

    this.authService.registerConfirm(confirmationToken).subscribe(
      () => {
        this.router.navigate(['/']);
      },
      () => {
        this.onRegisterConfirm = 'error';
      }
    );
  }
}
