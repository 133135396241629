import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { Form } from 'src/app/shared/models/forms/form.model';
import { FormField } from 'src/app/shared/models/forms/form-field.model';

import { MatchControlValueValidator } from 'src/app/shared/validators/match-control-value.validator';

import { AppService } from 'src/app/shared/services/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: Form;
  registerError: string;

  availableSlots: number;

  onRegister: string;
  onGetAvailableSlots: boolean;

  constructor(private appService: AppService, private authService: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    this.appService.setTitle('GENERAL.REGISTER');

    const minlengthError = {
      value: 'FORMS.ERRORS.PASSWORD_MIN_LENGTH',
      translationParams: {
        minlength: this.appService.config.PASSWORD_MIN_LENGTH
      }
    };

    this.registerForm = new Form([
      new FormField({
        name: 'firstName',
        type: 'text',
        placeholder: 'FORMS.FIRSTNAME',
        control: new FormControl('', [Validators.required]),
        prependIcon: 'fas fa-user',
        errors: {
          pattern: 'FORMS.ERRORS.FIRSTNAME'
        }
      }),
      new FormField({
        name: 'lastName',
        type: 'text',
        placeholder: 'FORMS.LASTNAME',
        control: new FormControl('', [Validators.required]),
        prependIcon: 'fas fa-user',
        errors: {
          pattern: 'FORMS.ERRORS.LASTNAME'
        }
      }),
      new FormField({
        name: 'email',
        type: 'email',
        placeholder: 'FORMS.EMAIL',
        control: new FormControl('', [
          Validators.required,
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        ]),
        prependIcon: 'fas fa-envelope',
        errors: {
          pattern: 'FORMS.ERRORS.EMAIL'
        }
      }),
      new FormField({
        name: 'password',
        type: 'password',
        placeholder: 'FORMS.PASSWORD',
        control: new FormControl(''),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: minlengthError,
          matchcontrol: 'FORMS.ERRORS.PASSWORD_EQUALS_REPEAT_PASSWORD'
        }
      }),
      new FormField({
        name: 'repeatPassword',
        type: 'password',
        placeholder: 'FORMS.REPEAT_PASSWORD',
        control: new FormControl(''),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: minlengthError,
          matchcontrol: 'FORMS.ERRORS.REPEAT_PASSWORD_EQUALS_PASSWORD'
        }
      })
    ]);

    const passwordControl = this.registerForm.controls.password;
    const repeatPasswordControl = this.registerForm.controls.repeatPassword;
    const passwordValidators = [Validators.required, Validators.minLength(this.appService.config.PASSWORD_MIN_LENGTH)];

    passwordControl.setValidators([
      ...passwordValidators,
      MatchControlValueValidator(this.registerForm.controls.repeatPassword)
    ]);

    repeatPasswordControl.setValidators([
      ...passwordValidators,
      MatchControlValueValidator(this.registerForm.controls.password)
    ]);

    this.onGetAvailableSlots = true;

    this.userService.getAvailableSlots().subscribe((data: any) => {
      this.availableSlots = data.nbSlots;
      this.onGetAvailableSlots = false;
    });
  }

  onRegisterFormSubmit() {
    const { firstName, lastName, email, password } = this.registerForm.controls;

    const params = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      lang: this.appService.lang
    };

    this.onRegister = 'true';

    this.authService.register(params).subscribe(
      () => {
        this.onRegister = 'success';
      },
      (error: HttpErrorResponse) => {
        const { type } = error.error;

        this.onRegister = 'error';

        if (type) {
          this.registerError = `REGISTER_PAGE.ERRORS.${error.error.type}`;
        }
      }
    );
  }
}
