import { FormGroup } from '@angular/forms';

import { FormField } from './form-field.model';
import { FormSelect } from './form-select.model';
import { FormDate } from './form-date.model';

export class Form extends FormGroup {
  f: { [key: string]: FormField | FormSelect | FormDate };

  constructor(fields: (FormField | FormSelect | FormDate)[]) {
    super({});

    this.f = {};

    for (const field of fields) {
      this.addControl(field.name, field.control);
      this.f[field.name] = field;
    }
  }
}
