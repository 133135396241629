import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { AuthComponent } from './auth.component';
import { RegisterComponent } from './register/register.component';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';

import { AuthRouting } from './auth.routing';

@NgModule({
  declarations: [
    AuthComponent,
    RegisterComponent,
    RegisterConfirmComponent,
    PasswordForgotComponent,
    PasswordResetComponent,
    LoginComponent
  ],
  imports: [CommonModule, SharedModule, AuthRouting]
})
export class AuthModule {}
