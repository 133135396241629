import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TwitterRouting } from './twitter.routing';

@NgModule({
  declarations: [],
  imports: [CommonModule, TwitterRouting]
})
export class TwitterModule {}
