import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { FormField } from '../../models/forms/form-field.model';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class FormFieldComponent implements OnInit {
  @Input() field: FormField;
  @Input() hideMarginBottom: boolean;

  constructor() {}

  ngOnInit(): void {}

  get errors(): (string | { value: string; translationParams: any })[] {
    const errors = [];
    const errorTypes = Object.keys(this.field.control.errors);

    for (const errorType of errorTypes) {
      errors.push(this.field.errors[errorType]);
    }

    return errors;
  }
}
