import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Media } from '../../models/medias/media.model';
import { Instagram } from '../../models/medias/instagram.model';
import { InstagramStats } from '../../models/stats/instagram-stats.model';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'instagram/';
  }

  getAccounts(facebookUserToken: string): Observable<Instagram[]> {
    const headers = {
      'Facebook-User-Token': facebookUserToken
    };

    return <Observable<Instagram[]>>this.http.get(`${this.API_PREFIX}accounts`, { headers });
  }

  getAccountStats(id: string, startDate: string, endDate: string): Observable<InstagramStats[]> {
    const url = `${this.API_PREFIX}${id}/stats/${startDate}/${endDate}`;
    return <Observable<InstagramStats[]>>this.http.get(url);
  }

  getScheduledPost(accounts: Media[], month: string): Observable<any[]> {
    const ids = accounts.map((account: Media) => account._id).join(',');
    const url = `${this.API_PREFIX}accounts/${ids}/calendar/${month}`;

    return <Observable<any[]>>this.http.get(url);
  }

  saveAccounts(accounts: Instagram[]): Observable<string[]> {
    return <Observable<string[]>>this.http.post(`${this.API_PREFIX}accounts`, accounts);
  }

  publish(params: any, accounts: Media[]): Observable<string[]> {
    const ids = accounts.map((account: Media) => account._id).join(',');
    const url = `${this.API_PREFIX}accounts/${ids}`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  schedule(params: any, accounts: Media[]): Observable<string[]> {
    const ids = accounts.map((account: Media) => account._id).join(',');
    const url = `${this.API_PREFIX}accounts/${ids}/schedule`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  deleteScheduledPost(accountId: string, postId: string): Observable<any> {
    const url = `${this.API_PREFIX}accounts/${accountId}/calendar/${postId}`;
    return <Observable<any>>this.http.delete(url);
  }
}
