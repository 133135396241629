import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'config/';
  }

  getConfig(): Promise<any> {
    return this.http.get(this.API_PREFIX).toPromise();
  }
}
