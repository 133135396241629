import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'auth/';
  }

  register(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}register`, params);
  }

  registerConfirm(confirmationToken: string): Observable<any> {
    return this.http.put(`${this.API_PREFIX}register/confirm/${confirmationToken}`, {}, { withCredentials: true });
  }

  login(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}login`, params, { withCredentials: true });
  }

  passwordForgotten(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}password/forgot`, params);
  }

  passwordReset(resetPasswordToken: string, params: any): Observable<any> {
    return this.http.put(`${this.API_PREFIX}password/reset/${resetPasswordToken}`, params, { withCredentials: true });
  }

  logout(): Observable<any> {
    return this.http.get(`${this.API_PREFIX}logout`);
  }

  authorized(): Observable<any> {
    return this.http.get(`${this.API_PREFIX}authorized`);
  }
}
