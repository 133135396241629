import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-twitter',
  templateUrl: './board-twitter.component.html',
  styleUrls: ['./board-twitter.component.css']
})
export class BoardTwitterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
