import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { Form } from 'src/app/shared/models/forms/form.model';
import { FormField } from 'src/app/shared/models/forms/form-field.model';

import { AppService } from 'src/app/shared/services/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: Form;
  loginError: string;

  onLogin: string;

  constructor(private router: Router, private appService: AppService, private authService: AuthService) {}

  ngOnInit(): void {
    this.appService.setTitle('GENERAL.LOGIN');

    this.loginForm = new Form([
      new FormField({
        name: 'email',
        type: 'email',
        placeholder: 'FORMS.EMAIL',
        control: new FormControl('', [
          Validators.required,
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        ]),
        prependIcon: 'fas fa-envelope',
        errors: {
          pattern: 'FORMS.ERRORS.EMAIL'
        }
      }),
      new FormField({
        name: 'password',
        type: 'password',
        placeholder: 'FORMS.PASSWORD',
        control: new FormControl('', [
          Validators.required,
          Validators.minLength(this.appService.config.PASSWORD_MIN_LENGTH)
        ]),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: {
            value: 'FORMS.ERRORS.PASSWORD_MIN_LENGTH',
            translationParams: {
              minlength: this.appService.config.PASSWORD_MIN_LENGTH
            }
          }
        }
      })
    ]);
  }

  onLoginFormSubmit() {
    const params = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    };

    this.onLogin = 'true';

    this.authService.login(params).subscribe(
      () => {
        this.router.navigate(['/']);
      },
      (error: HttpErrorResponse) => {
        const { type } = error.error;

        this.onLogin = 'error';

        if (type) {
          this.loginError = `LOGIN_PAGE.ERRORS.${error.error.type}`;
        }
      }
    );
  }
}
