import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/shared/services/app/app.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.setTitle('SETTINGS_NEWSLETTER_PAGE.META_TITLE');
  }
}
