import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './dashboard.component';
import { BoardComponent } from './board/board.component';
import { BoardLoadingComponent } from './board/board-loading/board-loading.component';
import { BoardErrorComponent } from './board/board-error/board-error.component';
import { BoardFacebookComponent } from './board/board-facebook/board-facebook.component';
import { BoardInstagramComponent } from './board/board-instagram/board-instagram.component';
import { BoardTwitterComponent } from './board/board-twitter/board-twitter.component';

import { DashboardRouting } from './dashboard.routing';

@NgModule({
  declarations: [
    DashboardComponent,
    BoardComponent,
    BoardLoadingComponent,
    BoardErrorComponent,
    BoardFacebookComponent,
    BoardInstagramComponent,
    BoardTwitterComponent
  ],
  imports: [CommonModule, SharedModule, DashboardRouting]
})
export class DashboardModule {}
