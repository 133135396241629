import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Media } from '../../models/medias/media.model';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  readonly API_PREFIX: string;

  medias: Media[];

  onGetMedias: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'medias/';
  }

  get mediasIds(): string[] {
    return this.medias.map((currentMedia: Media) => currentMedia._id);
  }

  getMedias(): Promise<any> {
    return this.http.get(this.API_PREFIX).toPromise();
  }

  setMedias(medias: Media[]) {
    this.medias = medias;
    this.orderMedias();
  }

  orderMedias(type1 = 'facebook', type2 = 'instagram', type3 = 'twitter') {
    this.medias = this.medias.sort((media1: Media, media2: Media) => {
      if (media1.type === type1 || (media1.type === type2 && media2.type === type3)) {
        return -1;
      }

      return 1;
    });
  }

  deleteMedia(_id: string): Observable<any> {
    return this.http.delete(`${this.API_PREFIX}${_id}`);
  }

  /**
   * Dispatch a mediasChange event to inform a change in medias.
   */
  dispatchMediasChange() {
    const event = new CustomEvent('mediasChange', {
      bubbles: true,
      cancelable: true,
      detail: {
        medias: this.medias
      }
    });

    document.dispatchEvent(event);
  }

  /**
   * Search function to find a social media in the dropdown.
   *
   * @param term - The search term.
   * @param media - The social media.
   *
   * @returns true if the social media correspond to the term, false if not.
   */
  search(term: string, media: Media): boolean {
    let { name, type } = media;

    /**
     * Normalize a string to NFD and replace accented characters with
     * their non-accentuated equivalent.
     */
    const formatString = (string: string) =>
      string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

    term = formatString(term);
    name = formatString(name);
    type = formatString(type);

    return name.includes(term) || type.includes(term);
  }
}
