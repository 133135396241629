import { Media } from '../../models/medias/media.model';

import { Post as PostInterface } from '../../interfaces/post/post.interface';

export class Post implements PostInterface {
  media: Media;
  date: string;
  message: string;
  medias: string[];
  haveVideo: boolean;

  constructor(post: PostInterface) {
    this.media = post.media;
    this.date = post.date;
    this.message = post.message;
    this.medias = post.medias;
    this.haveVideo = post.haveVideo;
  }
}
