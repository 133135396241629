import { Media as MediaInterface } from '../../interfaces/medias/media.interface';

export class Media implements MediaInterface {
  _id: string;
  id: string;
  name: string;
  image: string;
  type: string;
  token: string;
  tokenSecret?: string;

  constructor(media: MediaInterface) {
    this._id = media._id;
    this.id = media.id;
    this.name = media.name;
    this.image = media.image;
    this.type = media.type;
    this.token = media.token;

    if (media.tokenSecret) {
      this.tokenSecret = media.tokenSecret;
    }
  }
}
