import { FormControl } from '@angular/forms';

import { FormField as FormFieldInterface } from '../../interfaces/forms/form-field.interface';

export class FormField implements FormFieldInterface {
  name: string;
  type: string;
  control: FormControl;
  placeholder?: string;
  prependString?: string;
  prependIcon?: string;
  errors?: { [key: string]: string | { value: string; translationParams: any } };

  constructor(field: FormFieldInterface) {
    this.name = field.name;
    this.type = field.type;
    this.control = field.control;
    this.placeholder = field.placeholder;
    this.prependString = field.prependString;
    this.prependIcon = field.prependIcon;
    this.errors = field.errors;

    if (this.placeholder === undefined) {
      this.placeholder = '';
    }

    if (field.validator) {
      this.validator = field.validator;
    }
  }

  get valid(): boolean {
    return this.validator();
  }

  get value(): any {
    return this.control.value;
  }

  setValue(value: any) {
    this.control.setValue(value);
  }

  validator(): boolean {
    return this.control.valid;
  }
}
