import { FormControl } from '@angular/forms';

import { FormDate as FormDateInterface } from '../../interfaces/forms/form-date.interface';

export class FormDate implements FormDateInterface {
  name: string;
  minDate?: string;
  maxDate?: string;
  startDate?: string;
  placeholder?: string;
  control?: FormControl;

  constructor(date: FormDateInterface) {
    const { name, minDate, maxDate, startDate, placeholder, control } = date;

    this.name = name;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.startDate = startDate;
    this.placeholder = placeholder;
    this.control = control;

    if (this.control === undefined) {
      this.control = new FormControl('');
    }
  }

  get value(): any {
    return this.control.value;
  }

  setValue(value: any) {
    this.control.setValue(value);
  }
}
