import { Component, Input, OnInit } from '@angular/core';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { Media } from 'src/app/shared/models/medias/media.model';

import { Sort } from 'src/app/shared/enums/misc/sort.enum';

@Component({
  selector: 'app-board-base',
  templateUrl: './board-base.component.html',
  styleUrls: ['./board-base.component.scss']
})
export class BoardBaseComponent implements OnInit {
  REACTIONS: any;

  @Input() media: Media;
  @Input() dateStart: string;
  @Input() dateEnd: string;

  stats: any[];
  order: { type: any; sort: Sort };

  onGetStats: string;

  constructor() {}

  ngOnInit() {}

  getStats() {}

  sort() {}

  onHeaderCellClick(type: any) {
    if (type === this.order.type) {
      if (this.order.sort === Sort.ASC) {
        this.order.sort = Sort.DESC;
      } else {
        this.order.sort = Sort.ASC;
      }
    } else {
      this.order.sort = Sort.DESC;
    }

    this.order.type = type;
    this.sort();
  }

  onCellEnter(
    tooltip: NgbTooltip,
    context: { nbOnPosts: number; nbOnComments: number; total: number } | number | string
  ) {
    if (typeof context === 'string') {
      tooltip.open({
        context: {
          name: context
        }
      });
    } else {
      tooltip.open({ context });
    }
  }

  onCellLeave(tooltip: NgbTooltip) {
    tooltip.close();
  }
}
