import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url } = request;

    if (url.includes('assets')) {
      return next.handle(request);
    }

    const newRequest: any = {
      url: `${environment.API_URL}${url}`
    };

    if (url.includes('auth') === false || url.includes('auth/authorized') || url.includes('auth/logout')) {
      newRequest.withCredentials = true;
    }

    return next.handle(request.clone(newRequest));
  }
}
