import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { Moment } from 'moment';

import { Media } from 'src/app/shared/models/medias/media.model';
import { Form } from 'src/app/shared/models/forms/form.model';
import { FormDate } from 'src/app/shared/models/forms/form-date.model';

import { FormDateComponent } from 'src/app/shared/components/form-date/form-date.component';
import { BoardFacebookComponent } from './board-facebook/board-facebook.component';
import { BoardInstagramComponent } from './board-instagram/board-instagram.component';

import { DashboardService } from '../dashboard.service';
import { ToolsService } from 'src/app/shared/services/tools/tools.service';
import { MediaService } from 'src/app/shared/services/media/media.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {
  @ViewChild(NgSelectComponent) mediaSelect: NgSelectComponent;
  @ViewChild('dateStartForm') dateStartComponent: FormDateComponent;
  @ViewChild('dateEndForm') dateEndComponent: FormDateComponent;
  @ViewChild(BoardFacebookComponent) boardFacebookComponent: BoardFacebookComponent;
  @ViewChild(BoardInstagramComponent) boardInstagramComponent: BoardInstagramComponent;

  @Input() _id: string;
  @Input() _mediaId: string;
  @Input() dateStart: string;
  @Input() dateEnd: string;

  @Output() dateStartChange: EventEmitter<string>;
  @Output() dateEndChange: EventEmitter<string>;
  @Output() delete: EventEmitter<void>;

  media: Media;
  medias: Media[];
  previousMedia: Media;
  datePickerForm: Form;
  show: boolean;

  onUpdateMedia: boolean;
  onUpdateDate: boolean;
  onDelete: boolean;

  constructor(
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private toolsService: ToolsService,
    private dashboardService: DashboardService,

    public mediaService: MediaService
  ) {
    this.dateStartChange = new EventEmitter<string>();
    this.dateEndChange = new EventEmitter<string>();
    this.delete = new EventEmitter<void>();
  }

  ngOnInit() {
    this.datePickerForm = new Form([
      new FormDate({
        name: 'dateStart',
        startDate: this.dateStart
      }),
      new FormDate({
        name: 'dateEnd',
        startDate: this.dateEnd
      })
    ]);

    this.setMedias();

    this.media = this.medias.find((media: Media) => media._id === this._mediaId);
    this.previousMedia = this.media;

    setTimeout(() => {
      this.show = true;

      setTimeout(() => {
        this.setDateStartLimits();
        this.setDateEndLimits();
      });
    });
  }

  get disableHeader(): boolean {
    return this.onUpdateMedia || this.onUpdateDate || this.onDelete || this.onBoardGetStats === 'true';
  }

  get diffDaysDates(): number {
    const dateStart = this.toolsService.moment({ date: this.dateStart }) as Moment;
    const dateEnd = this.toolsService.moment({ date: this.dateEnd }) as Moment;

    return dateEnd.diff(dateStart, 'days', true);
  }

  get stats(): any[] {
    if (this.boardFacebookComponent || this.boardInstagramComponent) {
      switch (this.media.type) {
        case 'facebook':
          return this.boardFacebookComponent.stats;
        case 'instagram':
          return this.boardInstagramComponent.stats;
        default:
          break;
      }
    }

    return null;
  }

  get onBoardGetStats(): string {
    if (this.boardFacebookComponent) {
      return this.boardFacebookComponent.onGetStats;
    } else if (this.boardInstagramComponent) {
      return this.boardInstagramComponent.onGetStats;
    }

    return 'true';
  }

  getStats() {
    const dateStart = this.dateStartComponent.currentDate;
    const dateEnd = this.dateEndComponent.currentDate;
    const params: any = {
      dateStart: this.toolsService.moment({ dateArray: [dateStart.year, dateStart.month, dateStart.day] }),
      dateEnd: this.toolsService.moment({ dateArray: [dateEnd.year, dateEnd.month, dateEnd.day] })
    };

    this.onUpdateDate = true;

    switch (this.media.type) {
      case 'facebook':
        this.boardFacebookComponent.onGetStats = 'true';
        break;
      case 'instagram':
        this.boardInstagramComponent.onGetStats = 'true';
        break;
      default:
        break;
    }

    this.dashboardService
      .updateDate(this._id, params)
      .then(() => {
        this.setDateEndLimits();
        this.setDateStartLimits();

        switch (this.media.type) {
          case 'facebook':
            this.boardFacebookComponent.getStats();
            break;
          case 'instagram':
            this.boardInstagramComponent.getStats();
            break;
          default:
            break;
        }
      })
      .catch(() => {
        this.notifierService.notify('error', this.translateService.instant('DASHBOARD_PAGE.ERRORS.UPDATE_DATE'));
      })
      .finally(() => {
        this.onUpdateDate = false;
      });
  }

  setMedias() {
    this.medias = this.mediaService.medias.filter((media: Media) => media.type !== 'twitter');
  }

  setDateStartLimits() {
    let minDate: string | Moment;
    let maxDate: string | Moment;

    if (this.dateStartComponent.minDate === undefined) {
      minDate = (this.toolsService.moment({ date: this.dateEnd }) as Moment).subtract(5, 'month');
      maxDate = (this.toolsService.moment({ date: this.dateEnd }) as Moment).subtract(1, 'day');
    } else {
      const { year, month, day } = this.dateEndComponent.currentDate;

      minDate = (this.toolsService.moment({ dateArray: [year, month, day], format: false }) as Moment).subtract(
        5,
        'month'
      );
      maxDate = (this.toolsService.moment({ dateArray: [year, month, day], format: false }) as Moment).subtract(
        1,
        'day'
      );
    }

    minDate = this.toolsService.formatDate(minDate);
    maxDate = this.toolsService.formatDate(maxDate);

    this.dateStartComponent.setMinDate(minDate);
    this.dateStartComponent.setMaxDate(maxDate);
  }

  setDateEndLimits() {
    const currentDate = this.toolsService.moment();
    let minDate: string | Moment;
    let maxDate: string | Moment;

    if (this.dateEndComponent.minDate === undefined) {
      minDate = (this.toolsService.moment({ date: this.dateStart }) as Moment).add(1, 'day');
      maxDate = (this.toolsService.moment({ date: this.dateStart }) as Moment).add(5, 'month');
    } else {
      const { year, month, day } = this.dateStartComponent.currentDate;

      minDate = (this.toolsService.moment({ dateArray: [year, month, day], format: false }) as Moment).add(1, 'day');
      maxDate = (this.toolsService.moment({ dateArray: [year, month, day], format: false }) as Moment).add(5, 'month');
    }

    if (maxDate.isAfter(currentDate)) {
      maxDate = currentDate;
    }

    minDate = this.toolsService.formatDate(minDate as Moment);
    maxDate = this.toolsService.formatDate(maxDate as Moment);

    this.dateEndComponent.setMinDate(minDate);
    this.dateEndComponent.setMaxDate(maxDate as string);
  }

  updateDates() {
    const dateStart = this.dateStartComponent.currentDate;
    const dateEnd = this.dateEndComponent.currentDate;
    const dates: any = {
      dateStart: this.toolsService.moment({ dateArray: [dateStart.year, dateStart.month, dateStart.day] }),
      dateEnd: this.toolsService.moment({ dateArray: [dateEnd.year, dateEnd.month, dateEnd.day] })
    };

    this.dateStart = dates.dateStart;
    this.dateEnd = dates.dateEnd;

    this.dateStartChange.emit(this.dateStart);
    this.dateEndChange.emit(this.dateEnd);

    this.setDateEndLimits();
    this.setDateStartLimits();
  }

  onMediaChange(media: Media) {
    const params = {
      _mediaId: this.media._id
    };

    this.onUpdateMedia = true;

    this.dashboardService
      .updateMedia(this._id, params)
      .then(() => {
        this.previousMedia = media;
      })
      .catch(() => {
        this.media = this.previousMedia;
        this.notifierService.notify('error', this.translateService.instant('DASHBOARD_PAGE.ERRORS.UPDATE_MEDIA'));
      })
      .finally(() => {
        this.onUpdateMedia = false;
        this.mediaSelect.blur();
      });
  }

  onDateStartSelect() {
    this.updateDates();
  }

  onDateEndSelect() {
    this.updateDates();
  }

  onOKClick() {
    this.getStats();
  }

  onDeleteClick() {
    this.onDelete = true;

    this.dashboardService
      .delete(this._id)
      .then(() => {
        this.delete.emit();
      })
      .catch(() => {
        this.notifierService.notify('error', this.translateService.instant('DASHBOARD_PAGE.ERRORS.DELETE'));
      })
      .finally(() => {
        this.onDelete = false;
      });
  }

  @HostListener('document:mediasChange', ['$event.detail'])
  onMediasChange() {
    this.setMedias();
  }
}
