import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  readonly daysShort: any;
  readonly months: any;

  constructor(private translateService: TranslateService) {
    this.daysShort = {
      1: 'MO',
      2: 'TU',
      3: 'WE',
      4: 'TH',
      5: 'FR',
      6: 'SA',
      7: 'SU'
    };

    this.months = {
      1: 'JANUARY',
      2: 'FEBRUARY',
      3: 'MARCH',
      4: 'APRIL',
      5: 'MAY',
      6: 'JUNE',
      7: 'JULY',
      8: 'AUGUST',
      9: 'SEPTEMBER',
      10: 'OCTOBER',
      11: 'NOVEMBER',
      12: 'DECEMBER'
    };

    this.setMonthsStrings();

    this.translateService.onLangChange.subscribe(() => {
      this.setMonthsStrings();
    });
  }

  /**
   * Get a day short name from its number
   *
   * @param day - The day number.
   *
   * @returns The day short name.
   */
  getDayShortName(day: number) {
    return this.translateService.instant(`GENERAL.DAYS.${this.daysShort[day]}`);
  }

  /**
   * Get a month name from its number.
   *
   * @param month - The month number
   *
   * @returns The month name.
   */
  getMonthName(month: number) {
    return this.translateService.instant(`GENERAL.MONTHS.${this.months[month]}`);
  }

  /**
   * Convert a string representation of a date to an NgbDateStruct object.
   *
   * @param value - A string representation of a date.
   *
   * @returns An NgbDateStruct object or null.
   */
  getDateStruct(value: string = null): NgbDateStruct {
    if (value) {
      const date = value.split(' ');

      return {
        day: Number(date[0]),
        month: Number(this.months[date[1]]),
        year: Number(date[2])
      };
    }

    return null;
  }

  /**
   * Convert an NgbDateStruct object to string representation of a date.
   *
   * @param date - The date.
   *
   * @returns A string representation of a date or null.
   */
  getDateString(date: NgbDateStruct | null): string | null {
    if (date) {
      return `${date.day} ${this.getMonthName(date.month)} ${date.year}`;
    }

    return null;
  }

  /**
   * Convert a 24 hour time to a 12 hour time.
   *
   * @param time - The 24 hour time.
   *
   * @returns The 12 hour time.
   */
  get12HourTime(time: string): string {
    const timeArray = time.split(':');
    const minute = timeArray[1];
    let hour: any = Number(timeArray[0]);
    let meridian;

    if (hour >= 12) {
      meridian = 'PM';
    } else {
      meridian = 'AM';
    }

    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour -= 12;
    }

    if (hour.length === 1) {
      hour = `0${hour}`;
    }

    return `${hour}:${minute} ${meridian}`;
  }

  getDateDDMMYYYYHHMMFromTimestamp(timestamp: number): string {
    const date = new Date(timestamp);
    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();

    if (day < 10) {
      day = `0${day}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return `${day}-${month}-${date.getFullYear()} ${hours}:${minutes}`;
  }

  /**
   * Create month name indexes in the months array
   * and delete the uneeded ones.
   */
  setMonthsStrings() {
    for (const monthIndex in this.months) {
      if (isNaN(Number(monthIndex))) {
        delete this.months[monthIndex];
      } else {
        const month = this.getMonthName(Number(monthIndex));
        this.months[month] = monthIndex;
      }
    }
  }
}
