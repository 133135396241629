import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

import linkifyUrls from 'linkify-urls';

import { AppService } from '../../services/app/app.service';
import { DateService } from '../../services/date/date.service';

import { Media } from '../../models/medias/media.model';
import { Post } from '../../models/post/post.model';

declare let $: any;
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, AfterViewInit {
  readonly TEXT_MAX_CHARS: number;

  @Input() data: Post;
  readMore: boolean;

  constructor(private appService: AppService, private dateService: DateService) {
    this.TEXT_MAX_CHARS = 200;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.haveVideo) {
      const videoEl = $('app-post video');

      videoEl.prop('muted', true);
      videoEl.trigger('play');
    }
  }

  get media(): Media {
    return this.data.media;
  }

  get date(): string {
    const dateTimeArr = this.data.date.split(' ');
    const dateArr = dateTimeArr[0];
    const day = Number(dateArr.split('-')[0]);
    let month = this.dateService.getMonthName(Number(dateArr.split('-')[1]));
    let time = dateTimeArr[1];

    month = month.replace(month[0], month[0].toLowerCase());

    if (this.appService.lang === 'en') {
      time = this.dateService.get12HourTime(time);
    }

    return `${day} ${month}, ${time}`;
  }

  get message(): string {
    if (this.haveBigMessage && !this.readMore) {
      let reducedText = this.data.message.slice(0, this.TEXT_MAX_CHARS);

      if (reducedText.charAt(reducedText.length - 1) === ' ') {
        reducedText += '...';
      } else {
        reducedText += ' ...';
      }

      return linkifyUrls(reducedText, { attributes: { target: '_blank' } });
    }

    return linkifyUrls(this.data.message, { attributes: { target: '_blank' } });
  }

  get medias(): string[] {
    return this.data.medias;
  }

  get haveVideo(): boolean {
    return this.data.haveVideo;
  }

  get haveBigMessage(): boolean {
    return this.data.message.length > this.TEXT_MAX_CHARS;
  }

  toggleReadMore() {
    this.readMore = !this.readMore;
  }
}
