import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { PublisherComponent } from './publisher.component';

import { PublisherRouting } from './publisher.routing';

@NgModule({
  declarations: [PublisherComponent],
  imports: [CommonModule, SharedModule, PublisherRouting]
})
export class PublisherModule {}
