import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { SettingsUpdateButtonComponent } from './shared/settings-update-button/settings-update-button.component';
import { SettingsComponent } from './settings.component';
import { GeneralComponent } from './general/general.component';
import { AccountComponent } from './account/account.component';
import { NewsletterComponent } from './newsletter/newsletter.component';

import { SettingsRouting } from './settings.routing';

@NgModule({
  declarations: [
    SettingsUpdateButtonComponent,
    SettingsComponent,
    GeneralComponent,
    AccountComponent,
    NewsletterComponent
  ],
  imports: [CommonModule, SharedModule, SettingsRouting]
})
export class SettingsModule {}
