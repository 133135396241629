import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgbModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbPopoverModule,
  NgbDropdown
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotifierModule } from 'angular-notifier';

import { FormFieldComponent } from './components/form-field/form-field.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormDateRangeComponent } from './components/form-date-range/form-date-range.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { MediaBarComponent } from './components/media-bar/media-bar.component';
import { PostComponent } from './components/post/post.component';

@NgModule({
  declarations: [
    FormFieldComponent,
    FormSelectComponent,
    FormDateComponent,
    FormDateRangeComponent,
    DateTimePickerComponent,
    MediaBarComponent,
    PostComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    NgSelectModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right'
        },
        vertical: {
          position: 'top',
          distance: 70
        }
      }
    })
  ],
  providers: [NgbDropdown, FormGroupDirective],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    NgbModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NotifierModule,
    FormFieldComponent,
    FormSelectComponent,
    FormDateComponent,
    FormDateRangeComponent,
    DateTimePickerComponent,
    MediaBarComponent,
    PostComponent
  ]
})
export class SharedModule {}
