import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import appInfos from '../../../../assets/appInfos.json';
import appConfig from '../../../../assets/appConfig.json';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  readonly infos: any;
  readonly config: any;

  title: string;
  lang: string;
  maxMedias: number;

  onGetConfig: string;

  constructor(private titleService: Title, private translateService: TranslateService) {
    this.infos = appInfos;
    this.config = appConfig;
  }

  setTitle(title?: string) {
    if (title) {
      this.title = title;
    }

    if (this.title) {
      this.titleService.setTitle(`${this.translateService.instant(this.title)} - ${this.infos.NAME}`);
    }
  }

  setLang(lang: string, returnObservable?: boolean): Observable<any> {
    const availableLangs = this.config.LANGUAGES.map((currentLang: any) => currentLang.code);

    if (availableLangs.includes(lang) === false) {
      return;
    }

    this.lang = lang;

    if (returnObservable) {
      return this.translateService.use(this.lang);
    }

    this.translateService.use(this.lang).subscribe();
  }
}
