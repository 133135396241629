import { Component, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Media } from '../../models/medias/media.model';

import { MediaService } from '../../services/media/media.service';

declare let $: any;

@Component({
  selector: 'app-media-bar',
  templateUrl: './media-bar.component.html',
  styleUrls: ['./media-bar.component.scss']
})
export class MediaBarComponent implements OnInit {
  @Output() onChange: EventEmitter<void>;
  @Input() haveMargin: boolean;
  @Input() disable: boolean;

  selectedMedias: Media[];
  selectedIds: string[];

  constructor(public mediaService: MediaService) {
    this.onChange = new EventEmitter<void>();
  }

  ngOnInit() {
    this.selectedMedias = [];
    this.selectedIds = [];

    if (this.haveMargin === undefined) {
      this.haveMargin = true;
    }

    setTimeout(() => this.setHeight());
  }

  setHeight() {
    const mainHeight = $('main').height();
    const appContentEl = $('.app-content');
    const newHeight = `${mainHeight - Number(appContentEl.css('margin-top').replace('px', ''))}px`;

    $('app-media-bar #this').css('min-height', newHeight);
    $('app-media-bar #this-media-bar').css('max-height', newHeight);
  }

  setSelectedMedias(medias: Media[]) {
    this.selectedMedias = medias;
    this.selectedIds = this.selectedMedias.map((currentMedia: Media) => currentMedia._id);
  }

  onMediaClick(media: Media) {
    if (this.disable) {
      return;
    }

    if (this.selectedIds.includes(media._id)) {
      this.selectedMedias = this.selectedMedias.filter((currentMedia: Media) => currentMedia._id !== media._id);
    } else {
      this.selectedMedias.push(media);
    }

    this.selectedIds = this.selectedMedias.map((currentMedia: Media) => currentMedia._id);
    this.onChange.emit();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setHeight();
  }

  @HostListener('document:mediasChange')
  onMediasChange() {
    const mediaIds = this.mediaService.medias.map((media: Media) => media._id);

    this.selectedMedias = this.selectedMedias.filter((media: Media) => mediaIds.includes(media._id));
    this.selectedIds = this.selectedMedias.map((currentMedia: Media) => currentMedia._id);
  }

  @HostListener('document:twitterConnected')
  onTwitterConnected() {
    this.setHeight();
  }
}
