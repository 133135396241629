import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'stats-board/';
  }

  getAll(): Observable<any> {
    return this.http.get(this.API_PREFIX);
  }

  createStatsBoard(params: any): Promise<any> {
    return this.http.post(`${this.API_PREFIX}`, params).toPromise();
  }

  updateMedia(_id: string, params: any): Promise<any> {
    return this.http.put(`${this.API_PREFIX}${_id}/media`, params).toPromise();
  }

  updateDate(_id: string, params: any): Promise<any> {
    return this.http.put(`${this.API_PREFIX}${_id}/date`, params).toPromise();
  }

  delete(_id: string): Promise<any> {
    return this.http.delete(`${this.API_PREFIX}${_id}`).toPromise();
  }
}
