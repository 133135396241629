import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { Form } from 'src/app/shared/models/forms/form.model';
import { FormField } from 'src/app/shared/models/forms/form-field.model';

import { MatchControlValueValidator } from 'src/app/shared/validators/match-control-value.validator';

import { AuthService } from 'src/app/auth/auth.service';
import { AppService } from 'src/app/shared/services/app/app.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  passwordResetForm: Form;

  onPasswordReset: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.appService.setTitle('PASSWORD_RESET_PAGE.TITLE');

    const minlengthError = {
      value: 'FORMS.ERRORS.PASSWORD_MIN_LENGTH',
      translationParams: {
        minlength: this.appService.config.PASSWORD_MIN_LENGTH
      }
    };

    this.passwordResetForm = new Form([
      new FormField({
        name: 'password',
        type: 'password',
        placeholder: 'FORMS.PASSWORD',
        control: new FormControl(''),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: minlengthError,
          matchcontrol: 'FORMS.ERRORS.PASSWORD_EQUALS_REPEAT_PASSWORD'
        }
      }),
      new FormField({
        name: 'repeatPassword',
        type: 'password',
        placeholder: 'FORMS.REPEAT_PASSWORD',
        control: new FormControl(''),
        prependIcon: 'fas fa-lock',
        errors: {
          minlength: minlengthError,
          matchcontrol: 'FORMS.ERRORS.REPEAT_PASSWORD_EQUALS_PASSWORD'
        }
      })
    ]);

    const passwordControl = this.passwordResetForm.controls.password;
    const repeatPasswordControl = this.passwordResetForm.controls.repeatPassword;
    const passwordValidators = [Validators.required, Validators.minLength(this.appService.config.PASSWORD_MIN_LENGTH)];

    passwordControl.setValidators([
      ...passwordValidators,
      MatchControlValueValidator(this.passwordResetForm.controls.repeatPassword)
    ]);

    repeatPasswordControl.setValidators([
      ...passwordValidators,
      MatchControlValueValidator(this.passwordResetForm.controls.password)
    ]);
  }

  onPasswordResetFormSubmit() {
    const { resetPasswordToken } = this.activatedRoute.snapshot.params;
    const params = {
      password: this.passwordResetForm.controls.password.value
    };

    this.onPasswordReset = 'true';

    this.authService.passwordReset(resetPasswordToken, params).subscribe(
      () => {
        this.router.navigate(['/']);
      },
      () => {
        this.onPasswordReset = 'error';
      }
    );
  }
}
