import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import { Form } from 'src/app/shared/models/forms/form.model';
import { FormField } from 'src/app/shared/models/forms/form-field.model';

import { UserService } from 'src/app/shared/services/user/user.service';
import { AppService } from 'src/app/shared/services/app/app.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { MediaService } from 'src/app/shared/services/media/media.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  deleteForm: Form;

  onDelete: string;

  constructor(
    private router: Router,
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private appService: AppService,
    private storageService: StorageService,
    private userService: UserService,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.appService.setTitle('SETTINGS_ACCOUNT_PAGE.META_TITLE');

    this.deleteForm = new Form([
      new FormField({
        name: 'delete',
        type: 'text',
        placeholder: 'SETTINGS_ACCOUNT_PAGE.DELETE',
        control: new FormControl('', [
          Validators.required,
          Validators.pattern(this.translateService.instant('SETTINGS_ACCOUNT_PAGE.DELETE'))
        ]),
        prependIcon: 'fas fa-user-slash',
        errors: {
          pattern: 'SETTINGS_ACCOUNT_PAGE.ERRORS.ENTER_DELETE'
        }
      })
    ]);
  }

  onDeleteFormSubmit() {
    this.onDelete = 'true';

    this.userService.delete().subscribe(
      () => {
        this.onDelete = 'success';
        this.notifierService.notify('success', this.translateService.instant('SETTINGS_ACCOUNT_PAGE.SUCCESS.DELETE'));

        setTimeout(() => {
          this.appService.onGetConfig = 'false';
          this.mediaService.onGetMedias = 'false';
          this.userService.email = null;

          this.storageService.clear();
          this.storageService.deleteCookie('token', '/');
          this.router.navigate(['/login']);
        }, 2000);
      },
      () => {
        this.onDelete = 'error';
        this.notifierService.notify('error', this.translateService.instant('SETTINGS_ACCOUNT_PAGE.ERRORS.DELETE'));
      }
    );
  }
}
