import { ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * Verify if a control value matches another control and updates the other control accordingly.
 */
export function MatchControlValueValidator(controlToMatch: AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value !== controlToMatch.value) {
      // Update matching control if it has no matching error
      setTimeout(() => {
        const { errors } = controlToMatch;

        if (errors === null || errors.matchcontrol === undefined) {
          controlToMatch.updateValueAndValidity();
        }
      });

      return { matchcontrol: false };
    }

    // Update matching control if it has a matching error
    setTimeout(() => {
      const { errors } = controlToMatch;

      if (errors && errors.matchcontrol === false) {
        controlToMatch.updateValueAndValidity();
      }
    });

    return null;
  };
}
