import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Media } from '../../models/medias/media.model';
import { Facebook } from '../../models/medias/facebook.model';
import { FacebookStats } from '../../models/stats/facebookStats.model';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'facebook/';
  }

  getPages(facebookUserToken: string): Observable<Facebook[]> {
    const headers = {
      'Facebook-User-Token': facebookUserToken
    };

    return <Observable<Facebook[]>>this.http.get(`${this.API_PREFIX}pages`, { headers });
  }

  getScheduledPost(pages: Facebook[], month: string): Observable<any[]> {
    const ids = pages.map((page: Media) => page._id).join(',');
    const url = `${this.API_PREFIX}pages/${ids}/calendar/${month}`;

    return <Observable<any[]>>this.http.get(url);
  }

  getPageStats(id: string, startDate: string, endDate: string): Observable<FacebookStats[]> {
    const url = `${this.API_PREFIX}pages/${id}/stats/${startDate}/${endDate}`;
    return <Observable<FacebookStats[]>>this.http.get(url);
  }

  savePages(pages: Facebook[]): Observable<string[]> {
    return <Observable<string[]>>this.http.post(`${this.API_PREFIX}pages`, pages);
  }

  publish(params: any, pages: Media[]): Observable<string[]> {
    const ids = pages.map((page: Media) => page._id).join(',');
    const url = `${this.API_PREFIX}pages/${ids}`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  publishVideo(params: any, pages: Media[]): Observable<string[]> {
    const ids = pages.map((page: Media) => page._id).join(',');
    const url = `${this.API_PREFIX}pages/${ids}/video`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  schedule(params: any, pages: Media[]): Observable<string[]> {
    const ids = pages.map((page: Media) => page._id).join(',');
    const url = `${this.API_PREFIX}pages/${ids}/schedule`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  deleteScheduledPost(pageId: string, postId: string): Observable<any> {
    const url = `${this.API_PREFIX}pages/${pageId}/calendar/${postId}`;
    return <Observable<any>>this.http.delete(url);
  }
}
