import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Media } from '../../models/medias/media.model';
import { Twitter } from '../../models/medias/twitter.model';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {
  readonly API_PREFIX: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'twitter/';
  }

  getRequestToken(): Observable<any> {
    return this.http.get(`${this.API_PREFIX}token/request`);
  }

  getScheduledPost(accounts: Media[], month: string): Observable<any[]> {
    const ids = accounts.map((account: Media) => account._id).join(',');
    const url = `${this.API_PREFIX}accounts/${ids}/calendar/${month}`;

    return <Observable<any[]>>this.http.get(url);
  }

  saveAccount(params: any): Observable<any> {
    return <Observable<Twitter>>this.http.post(`${this.API_PREFIX}account`, params);
  }

  publish(params: any, accounts: Media[]): Observable<string[]> {
    const ids = accounts.map((account: Media) => account._id).join(',');
    const url = `${this.API_PREFIX}accounts/${ids}`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  schedule(params: any, accounts: Media[]): Observable<string[]> {
    const ids = accounts.map((account: Media) => account._id).join(',');
    const url = `${this.API_PREFIX}accounts/${ids}/schedule`;

    return <Observable<string[]>>this.http.post(url, params);
  }

  deleteScheduledPost(accountId: string, postId: string): Observable<any> {
    const url = `${this.API_PREFIX}accounts/${accountId}/calendar/${postId}`;
    return <Observable<any>>this.http.delete(url);
  }
}
