import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth.component';
import { RegisterComponent } from './register/register.component';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'register',
        children: [
          { path: '', component: RegisterComponent },
          {
            path: 'confirm/:confirmationToken',
            component: RegisterConfirmComponent
          },
          {
            path: '**',
            redirectTo: ''
          }
        ]
      },
      {
        path: 'password',
        children: [
          {
            path: 'forgot',
            component: PasswordForgotComponent
          },
          {
            path: 'reset/:resetPasswordToken',
            component: PasswordResetComponent
          },
          {
            path: '**',
            redirectTo: '/login'
          }
        ]
      },
      {
        path: 'login',
        component: LoginComponent
      },
      { path: '**', redirectTo: '/' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthRouting {}
