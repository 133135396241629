import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SettingsComponent } from './settings.component';
import { GeneralComponent } from './general/general.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { AccountComponent } from './account/account.component';

import { AuthGuard } from '../shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'general', component: GeneralComponent },
      // { path: 'newsletter', component: NewsletterComponent },
      { path: 'account', component: AccountComponent },
      { path: '**', redirectTo: 'general' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SettingsRouting {}
