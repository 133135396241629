import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly API_PREFIX: string;

  firstName: string;
  lastName: string;
  email: string;

  constructor(private http: HttpClient) {
    this.API_PREFIX = 'users/';
  }

  getAvailableSlots(): Observable<any> {
    return this.http.get(`${this.API_PREFIX}available-slots`);
  }

  delete(): Observable<any> {
    return this.http.delete(this.API_PREFIX);
  }
}
